import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Project from '../pages/Project'
import New from '../pages/New'
import TextEdit from '../pages/TextEdit'
import Texts from '../pages/Texts'
import Home from '../pages/Home'
import About from '../pages/About'
import Contact from '../pages/Contact'
import Privacy from '../pages/Privacy'
import Tos from '../pages/Tos'
import Me from '../pages/Me'
import SignIn from '../pages/SignIn'
import { selectHasLoggedIn } from '../store/userSlice'
import { useAppSelector } from '../app/hooks'
import Help from '../pages/Help'
import Auth from '../pages/Auth'
import Credentials from '../pages/Credentials'

const Page: React.FC<{}> = () => {
  const hasLoggedIn = useAppSelector(selectHasLoggedIn)
  if (hasLoggedIn) {
    return <Switch>
      <Route path="/new" exact><New /></Route>
      <Route path="/texts/:id/edit"><TextEdit /></Route>
      <Route path="/projects/:id"><Project /></Route>
      <Route path="/texts" exact><Texts /></Route>
      <Route path="/home" exact><Home /></Route>
      <Route path="/about" exact><About /></Route>
      <Route path="/contact" exact><Contact /></Route>
      <Route path="/privacy" exact><Privacy /></Route>
      <Route path="/tos" exact><Tos /></Route>
      <Route path="/me" exact><Me /></Route>
      <Route path="/auth/authorization" exact><Auth /></Route>
      <Route path="/auth/credentials" exact><Credentials /></Route>
      <Route path="/help"><Help /></Route>
      <Redirect to="/home" />
    </Switch>
  } else {
    return <Switch>
      <Route path="/" exact><About /></Route>
      <Route path="/about" exact><About /></Route>
      <Route path="/contact" exact><Contact /></Route>
      <Route path="/privacy" exact><Privacy /></Route>
      <Route path="/tos" exact><Tos /></Route>
      <Route path="/signin" exact><SignIn /></Route>
      <Route path="/auth/authorization" exact><SignIn /></Route>
      <Route path="/help"><Help /></Route>
      <Redirect to="/" />
    </Switch>
  }
}

export default Page
