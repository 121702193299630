import React, { useContext, useEffect, useState } from 'react'
import { ReactComponent as MoreVertIcon } from 'material-design-icons/navigation/svg/production/ic_more_vert_24px.svg'
import { characterCountOfText, titleOfText, subtitleOfText, TextListItemData, updateTimeOfText } from '../../../repository/Models'
import Sortable from '../Sortable'
import { useAppDispatch } from '../../../app/hooks'
import { NetworkStoreContext } from '../../../store/NetworkStore'
import { showTextContextMenu } from '../../../app/server'
import { getTemporaryText } from '../../../storage/Draft'

export interface TextCardProps {
  text: TextListItemData
}

const TextCard: React.FC<TextCardProps> = props => {
  const dispatch = useAppDispatch()
  const network = useContext(NetworkStoreContext)
  const path = `/texts/${props.text.id}/edit`
  const [hasDraft, setHasDraft] = useState(false)
  useEffect(() => {
    getTemporaryText(props.text.id)
      .then(temp => {
        if (temp) {
          setHasDraft(temp !== null)
        }
      })
  }, [props.text.id])
  return <Sortable type={'card'} item={props.text} path={path}>
    <div className={'flex-grow py-8px pl-16px pr-4px flex items-center'}>
      <span className={'flex-grow'}>{titleOfText(props.text)}</span>
      <MoreVertIcon className={'shrink-0 p-8px w-40px h-40px rounded-full hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={e => {
        showTextContextMenu(e, dispatch, network, props.text)
        e.preventDefault()
      }} />
    </div>
    <div className={'px-16px pb-12px grow-0 text-right'}>{characterCountOfText(props.text)}</div>
    <div className={'px-16px pb-16px grow-0 text-right'}>{updateTimeOfText(props.text)}{hasDraft ? '*' : ''}</div>
    <div className={'p-16px grow-0 border-t border-lightBorder dark:border-darkBorder'}>{subtitleOfText(props.text)}</div>
  </Sortable>
}

export default TextCard
