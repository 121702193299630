import React from 'react'
import AlertModal from '../components/modals/AlertModal'
import InputModal from '../components/modals/InputModal'
import LoadingModal from '../components/modals/LoadingModal'
import { postAnonymousFeedback } from '../api/ApiRequest'
import { sendFeedback } from '../repository/Send'
import { createProject, createText } from '../repository/Create'
import { ProjectListItemData, SectionListItemData, TextData, TextListItemData } from '../repository/Models'
import { removeContent } from '../repository/Remove'
import { updateContent, updateSectionOrder } from '../repository/Update'
import { showContextMenu } from '../store/contextMenuSlice'
import { dismissModal, showModal } from '../store/modalSlice'
import { NetworkStateReducer } from '../store/NetworkStore'
import { addEmptyProject, addNewText, deleteContent, reorderSection, updateTitle } from '../store/serverSlice'
import { showToast } from '../store/toastSlice'
import { AppDispatch } from './store'

export function sortSection (toIndex: number, section: SectionListItemData, project: ProjectListItemData, dispatch: AppDispatch, networkStore: NetworkStateReducer) {
  dispatch(showModal({ component: LoadingModal }))
  // WIP: deprecated api
  updateSectionOrder(section.id, toIndex + 1, networkStore)
    .then(newSection => {
      dispatch(reorderSection({
        project: project,
        newSection: newSection,
        newIndex: toIndex
      }))
    })
    .catch(() => {
      dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
    })
    .finally(() => {
      dispatch(dismissModal())
    })
}

export function showNewProjectModal (dispatch: AppDispatch, networkStore: NetworkStateReducer, onSuccess: (project: ProjectListItemData) => void) {
  dispatch(showModal({
    component () {
      return <InputModal
        message={'新規プロジェクト'}
        placeholder={'プロジェクト名'}
        positive={{
          label: '作成',
          onSubmit (text: string) {
            dispatch(showModal({
              component: LoadingModal
            }))
            createProject(text === '' ? null : text, networkStore)
              .then(result => {
                dispatch(addEmptyProject(result))
                onSuccess(result)
              })
              .catch(() => {
                dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
              })
              .finally(() => dispatch(dismissModal()))
          }
        }}
        negative={{
          label: 'キャンセル',
          onClick () {
            dispatch(dismissModal())
          }
        }}
      />
    }
  }))
}

export function showNewTextSectionModal (dispatch: AppDispatch, networkStore: NetworkStateReducer, project: ProjectListItemData, onSuccess: (section: TextData) => void) {
  dispatch(showModal({
    component () {
      return <InputModal
        message={'新規テキスト'}
        placeholder={'テキスト名'}
        positive={{
          label: '作成',
          onSubmit (text: string) {
            dispatch(showModal({
              component: LoadingModal
            }))
            createText('', text === '' ? null : text, project, networkStore)
              .then(result => {
                dispatch(addNewText(result))
                onSuccess(result)
              })
              .catch(() => {
                dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
              })
              .finally(() => dispatch(dismissModal()))
          }
        }}
        negative={{
          label: 'キャンセル',
          onClick () {
            dispatch(dismissModal())
          }
        }}
      />
    }
  }))
}

export function showUploadTextModal (dispatch: AppDispatch, networkStore: NetworkStateReducer, file: File, project: ProjectListItemData | undefined, onSuccess: (text: TextData) => void) {
  dispatch(showModal({
    component () {
      return <InputModal
        message={'新規テキスト'}
        placeholder={'テキスト名'}
        defaultValue={file.name}
        positive={{
          label: 'アップロード',
          onSubmit (text: string) {
            dispatch(showModal({
              component: LoadingModal
            }))
            file.text()
              .then(document => {
                createText(document, !text ? null : text, project, networkStore)
                  .then(result => {
                    dispatch(addNewText(result))
                    onSuccess(result)
                  })
                  .finally(() => dispatch(dismissModal()))
              })
              .catch(() => dispatch(dismissModal()))
          }
        }}
        negative={{
          label: '中止',
          onClick () {
            dispatch(dismissModal())
          }
        }}
      />
    }
  }))
}

interface Position {
  clientX: number
  clientY: number
}

function showRenameModal (contentId: number, parentId: number | undefined, textId: number | undefined, dispatch: AppDispatch, networkStore: NetworkStateReducer) {
  dispatch(showModal({
    component () {
      return <InputModal
      message={'タイトルを変更'}
      placeholder={'タイトルを入力'}
      positive={{
        label: '変更する',
        onSubmit (text: string) {
          dispatch(showModal({
            component: LoadingModal
          }))
          updateContent(contentId, text, networkStore)
            .then(result => {
              dispatch(updateTitle({
                contentId,
                parentId,
                textId,
                title: result.contentTitle ?? null
              }))
              dispatch(showToast({ type: 'success', message: '変更しました' }))
            })
            .catch(() => {
              dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
            })
            .finally(() => dispatch(dismissModal()))
        }
      }}
      negative={{
        label: 'キャンセル',
        onClick () {
          dispatch(dismissModal())
        }
      }}
    />
    }
  }))
}

function showDeleteModal (contentId: number, parentId: number | undefined, text: {
  id: number
  lineCount: number
  characterCount: number
} | null, dispatch: AppDispatch, networkStore: NetworkStateReducer) {
  dispatch(showModal({
    component () {
      return <AlertModal
      message={'削除しますか？'}
      positive={{
        label: '削除する',
        onClick () {
          dispatch(showModal({
            component: LoadingModal
          }))
          removeContent(contentId, networkStore)
            .then(() => {
              dispatch(deleteContent({
                contentId,
                text,
                parentId
              }))
              dispatch(showToast({ type: 'success', message: '削除しました' }))
            })
            .catch(() => {
              dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
            })
            .finally(() => dispatch(dismissModal()))
        }
      }}
      negative={{
        label: 'キャンセル',
        onClick () {
          dispatch(dismissModal())
        }
      }}
    />
    }
  }))
}

export function showProjectContextMenu (position: Position, dispatch: AppDispatch, networkStore: NetworkStateReducer, project: ProjectListItemData) {
  dispatch(showContextMenu({
    position,
    items: [
      {
        label: 'タイトルを変更',
        onClick () {
          showRenameModal(project.id, undefined, project.text?.id, dispatch, networkStore)
        }
      },
      {
        label: '削除',
        onClick () {
          showDeleteModal(project.id, undefined, project.text, dispatch, networkStore)
        }
      }
    ]
  }))
}

export function showTextContextMenu (position: Position, dispatch: AppDispatch, networkStore: NetworkStateReducer, text: TextListItemData) {
  dispatch(showContextMenu({
    position,
    items: [
      {
        label: 'タイトルを変更',
        onClick () {
          showRenameModal(text.content.id, text.project?.id, text.id, dispatch, networkStore)
        }
      },
      {
        label: '削除',
        onClick () {
          showDeleteModal(text.content.id, text.project?.id, text, dispatch, networkStore)
        }
      }
      // WIP: 別プロジェクトに移動
    ]
  }))
}

export function showSectionContextMenu (position: Position, dispatch: AppDispatch, networkStore: NetworkStateReducer, section: SectionListItemData, project: ProjectListItemData) {
  dispatch(showContextMenu({
    position,
    items: [
      {
        label: 'タイトルを変更',
        onClick () {
          showRenameModal(section.id, project.id, section.text?.id, dispatch, networkStore)
        }
      },
      {
        label: '削除',
        onClick () {
          showDeleteModal(section.id, project.id, section.text, dispatch, networkStore)
        }
      }
    ]
  }))
}

async function promiseFeedback (type: 'help' | 'feedback', subtype: string, message: string, hasLoggedIn: boolean, networkStore: NetworkStateReducer): Promise<{}> {
  return hasLoggedIn ? sendFeedback(type, subtype, message, networkStore) : postAnonymousFeedback(type, subtype, message)
}

export function feedback (type: 'help' | 'feedback', subtype: string, message: string, dispatch: AppDispatch, hasLoggedIn: boolean, networkStore: NetworkStateReducer) {
  if (message.length === 0) {
    dispatch(showToast({
      type: 'error',
      message: 'フィードバックが入力されていません'
    }))
    return
  }
  dispatch(showModal({ component: LoadingModal }))
  promiseFeedback(type, subtype, message, hasLoggedIn, networkStore)
    .then(() => {
      dispatch(showToast({
        type: 'success',
        message: 'フィードバックありがとうございます！'
      }))
    })
    .catch(() => {
      dispatch(showToast({
        type: 'error',
        message: '通信に失敗しました'
      }))
    })
    .finally(() => {
      dispatch(dismissModal())
    })
}
