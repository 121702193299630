import React, { useContext, useRef } from 'react'
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom'
import { NetworkStoreContext, updateTokenIfNeeded } from '../store/NetworkStore'
import { dismissModal, showModal } from '../store/modalSlice'
import LoadingModal from '../components/modals/LoadingModal'
import { useAppDispatch } from '../app/hooks'
import { showToast } from '../store/toastSlice'

const Auth: React.FC<{}> = () => {
  const history = useHistory()
  const { search } = useLocation()
  const responseType = new URLSearchParams(search).get('response_type')
  const clientId = new URLSearchParams(search).get('client_id')
  const state = new URLSearchParams(search).get('state')
  const scope = new URLSearchParams(search).get('scope')
  const redirectUri = new URLSearchParams(search).get('redirect_uri')
  const codeChallenge = new URLSearchParams(search).get('code_challenge')
  const codeChallengeMethod = new URLSearchParams(search).get('code_challenge_method')
  const network = useContext(NetworkStoreContext)
  const dispatch = useAppDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const tokenRef = useRef<HTMLInputElement>(null)
  if (
    responseType !== 'code' ||
    !clientId ||
    !state ||
    !redirectUri ||
    !codeChallenge ||
    codeChallengeMethod !== 'S256'
  ) {
    return <Switch>
      <Redirect to="/"/>
    </Switch>
  }
  return (
    <div className={'min-h-heightMain flex flex-col items-center justify-around w-full bg-brand bg-opacity-10 bg-no-repeat bg-center bg-cover md:bg-contain bg-logo'}>
      <div className={'p-24px flex flex-col items-center w-widthPanel max-w-widthPanel m-24px rounded-16px shadow bg-lightSurface dark:bg-darkSurface'}>
        <h2 className={'mb-24px text-24px'}>アクセスの許可</h2>
        <p className={'mb-24px w-full'}>続行すると「TATEditor for Visual Studio Code」へテキストやプロジェクトへのアクセス権限を与えます。</p>
        <div className={'flex'}>
          <button className={'btn-normal mr-8px'} onClick={() => {
            history.replace('/')
          }}>キャンセル</button>
          <form ref={formRef} action={`${process.env.REACT_APP_TATEDITOR_API ?? 'https://api.tateditor.app'}` + '/auth/authorization'} method={'POST'} onSubmit={e => {
            e.preventDefault()
            dispatch(showModal({ component: LoadingModal }))
            updateTokenIfNeeded(network)
              .then(token => {
                if (!tokenRef.current) {
                  return
                }
                tokenRef.current.value = token
                formRef.current?.submit()
              })
              .catch(() => {
                dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
              })
              .finally(() => {
                dispatch(dismissModal())
              })
          }}>
            <input type="hidden" name="response_type" value={responseType}/>
            <input type="hidden" name="client_id" value={clientId}/>
            <input type="hidden" name="state" value={state}/>
            <input type="hidden" name="scope" value={scope ?? undefined}/>
            <input type="hidden" name="redirect_uri" value={redirectUri}/>
            <input type="hidden" name="code_challenge" value={codeChallenge}/>
            <input type="hidden" name="code_challenge_method" value={codeChallengeMethod}/>
            <input type="hidden" name="token" value={''} ref={tokenRef}/>
            <button className={'btn-primary'}>許可する</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Auth
