import React, { useEffect, useContext, useState } from 'react'
import { NetworkStateReducer, NetworkStoreContext } from '../../store/NetworkStore'
import LoadingCover from './LoadingCover'
import LoadingError from './LoadingError'
import { getAuth, signOut } from 'firebase/auth'

export interface LoadProps<T> {
  fetch(network: NetworkStateReducer): Promise<T>
  dispatch(result: T): void
}

type LoadingStatus = 'loading' | 'error' | 'notFound' | 'maintenance' | 'disabledUser'

export function useLoading<T> (props: LoadProps<T>): [LoadingStatus, () => void] {
  const networkStore = useContext(NetworkStoreContext)
  const [state, setState] = useState<{
    loadingStatus: 'waiting' | 'loading' | 'error' | 'notFound'
  }>({
    loadingStatus: 'waiting'
  })
  const setWaiting = () => setState({ loadingStatus: 'waiting' })
  useEffect(() => {
    if (state.loadingStatus !== 'waiting') {
      return
    }
    setState({
      loadingStatus: 'loading'
    })
    props.fetch(networkStore)
      .then(props.dispatch)
      .catch(error => {
        if (error?.response?.status === 503) {
          networkStore.dispatch({ maintenanceMode: true })
        }
        setState({
          loadingStatus: error?.response?.status === 404 ? 'notFound' : 'error'
        })
      })
  }, [networkStore, state, setState, props])
  if (networkStore.state.disabledUser) {
    return ['disabledUser', setWaiting]
  }
  if (state.loadingStatus === 'notFound') {
    return [state.loadingStatus, setWaiting]
  }
  if (state.loadingStatus === 'error') {
    if (networkStore.state.maintenanceMode) {
      return ['maintenance', setWaiting]
    }
    return [state.loadingStatus, setWaiting]
  }
  return ['loading', setWaiting]
}

function PageLoad<T> (props: LoadProps<T>): React.ReactElement<any, any> | null {
  const [loadingStatus, setWaiting] = useLoading(props)
  if (loadingStatus === 'disabledUser') {
    return <LoadingError mode="disabledUser" onError={() => {
      signOut(getAuth())
    }}/>
  }
  if (loadingStatus === 'notFound') {
    return <LoadingError mode="notFound" onError={() => {}}/>
  }
  if (loadingStatus === 'error' || loadingStatus === 'maintenance') {
    return <LoadingError mode={loadingStatus} onError={() => {
      setWaiting()
    }}/>
  }
  return <LoadingCover/>
}

export default PageLoad
