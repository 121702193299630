import React, { useState } from 'react'
import { fetchTextList } from '../repository/Fetch'
import { TextListItemData } from '../repository/Models'
import { getSetting, saveSetting } from '../storage/Settings'
import PageLoad from '../components/loading/PageLoad'
import ActionMenuHeader from '../components/list/ActionMenuHeader'
import ListHeader from '../components/list/ListHeader'
import CardListContainer from '../components/list/CardListContainer'
import LineListContainer from '../components/list/LineListContainer'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectAllTexts, setTexts } from '../store/serverSlice'

const Texts: React.FC = () => {
  const texts = useAppSelector(selectAllTexts)
  const dispatch = useAppDispatch()
  const [viewCard, setViewCard] = useState(getSetting('text-list-view') !== 'list')
  if (texts === undefined) {
    return <PageLoad<{
      textList: {
        texts: TextListItemData[]
      }
    }>
      fetch={accessToken => fetchTextList(accessToken)}
      dispatch={response => dispatch(setTexts(response.textList.texts))}
    />
  }
  return <>
    <div className={'fixed w-full h-48px border-b border-lightBorder dark:border-darkBorder bg-lightSurface dark:bg-darkSurface'}>
      <ActionMenuHeader mode={'textList'} />
    </div>
    <div className={'mt-48px p-8px md:px-40px md:py-24px'}>
      <ListHeader
        title='すべてのテキスト'
        changeToListView={() => {
          saveSetting('text-list-view', 'list')
          setViewCard(false)
        }}
        changeToCardView={() => {
          saveSetting('text-list-view', 'card')
          setViewCard(true)
        }} />
      {viewCard
        ? <CardListContainer texts={texts} />
        : <LineListContainer texts={texts} />}
    </div>
  </>
}

export default Texts
