import React from 'react'

const AlertModal: React.FC<{
  message: string
  positive: {
    label: string
    onClick: () => void
  }
  negative: {
    label: string
    onClick: () => void
  }
}> = props => {
  return <div className={'p-16px'}>
    <p className={'mb-16px'}>{props.message}</p>
    <div className={'flex justify-end'}>
      <button className={'btn-normal mr-8px'} onClick={props.negative.onClick}>{props.negative.label}</button>
      <button className={'btn-primary'} onClick={props.positive.onClick}>{props.positive.label}</button>
    </div>
  </div>
}

export default AlertModal
