import React, { useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { sortSection } from '../../app/server'
import { ProjectListItemData, SectionListItemData, TextListItemData, TextSectionListItemData } from '../../repository/Models'
import { NetworkStoreContext } from '../../store/NetworkStore'

export interface SortableProps {
  path?: string
  type: 'card' | 'list'
  item?: TextListItemData | TextSectionListItemData | ProjectListItemData | {
    index: number
    section: SectionListItemData
    project: ProjectListItemData
  }
  onClick?: () => void
  children: React.ReactNode
}

const Sortable: React.FC<SortableProps> = props => {
  const dispatch = useDispatch()
  const networkStore = useContext(NetworkStoreContext)
  const onDrop: React.DragEventHandler<HTMLElement> = useMemo(() => {
    return e => {
      if (props.item === undefined) {
        return
      }
      const json = e.dataTransfer.getData('application/json')
      if (!json) {
        return
      }
      const item = JSON.parse(json)
      if ('content' in props.item) {
        if ('content' in item) {
          // nothing
        }
      } else if ('textCount' in props.item) {
        if ('textCount' in item) {
          // nothing
        }
      } else if ('displayNumber' in props.item) {
        if ('displayNumber' in item) {
          // nothing
        }
      } else if ('section' in props.item) {
        if ('index' in item && 'section' in item && 'project' in item) {
          const toIndex = props.item.index
          const index: number = item.index
          const section: SectionListItemData = item.section
          const project: ProjectListItemData = item.project
          if (project.id !== props.item.project.id || index === toIndex) {
            return
          }
          sortSection(toIndex, section, project, dispatch, networkStore)
        }
      }
    }
  }, [networkStore, props.item])
  if (props.path) {
    return <Link
      className={props.type === 'card'
        ? 'h-heightCard flex flex-col border rounded-8px hover:shadow dark:hover:bg-darkSurfaceHover border-lightBorder dark:border-darkBorder'
        : 'h-48px block border-b border-lightBorder dark:border-darkBorder hover:bg-lightSurfaceHover dark:hover:bg-darkSurfaceHover'}
      draggable={true}
      onClick={props.onClick}
      onDragOver={e => e.preventDefault()}
      onDragStart={e => e.dataTransfer.setData('application/json', JSON.stringify(props.item))}
      onDrop={onDrop}
      to={props.path}
    >
      {props.children}
    </Link>
  } else {
    return <div
      className={props.type === 'card'
        ? 'h-heightCard flex flex-col border rounded-8px hover:shadow cursor-pointer border-lightBorder dark:border-darkBorder'
        : 'h-48px border-b border-lightBorder dark:border-darkBorder hover:bg-lightSurfaceHover dark:hover:bg-darkSurfaceHover'}
      draggable={true}
      onClick={props.onClick}
      onDragOver={e => e.preventDefault()}
      onDragStart={e => e.dataTransfer.setData('application/json', JSON.stringify(props.item))}
      onDrop={onDrop}
    >
      {props.children}
    </div>
  }
}

export default Sortable
