import React from 'react'
import ProjectLineItem from './lines/ProjectLineItem'
import TextLineItem from './lines/TextLineItem'
import SectionLineItem from './lines/SectionLineItem'
import { ProjectListItemData, SectionListItemData, TextListItemData } from '../../repository/Models'
import EmptyCard from './cards/EmptyCard'
import CardContainer from './cards/CardContainer'

interface ProjectLineListContainerProps {
  projects: ProjectListItemData[]
}

interface SectionLineListContainerProps {
  project: ProjectListItemData
  sections: SectionListItemData[]
}

interface TextLineListContainerProps {
  texts: TextListItemData[]
}

export type LineListContainerProps = ProjectLineListContainerProps | SectionLineListContainerProps | TextLineListContainerProps

const LineListContainer: React.FC<LineListContainerProps> = props => {
  if ('projects' in props) {
    if (props.projects.length === 0) {
      return <div className={'-mx-8px pb-16px flex flex-wrap justify-start'}>
        <CardContainer><EmptyCard type={'project'} parent={null}/></CardContainer>
      </div>
    }
  } else if ('sections' in props) {
    if (props.sections.length === 0) {
      return <div className={'-mx-8px pb-16px flex flex-wrap justify-start'}>
        <CardContainer><EmptyCard type={'text'} parent={props.project}/></CardContainer>
      </div>
    }
  } else {
    if (props.texts.length === 0) {
      return <div className={'-mx-8px pb-16px flex flex-wrap justify-start'}>
        <CardContainer><EmptyCard type={'text'} parent={null}/></CardContainer>
      </div>
    }
  }
  return (
    <>
      {('projects' in props) ? props.projects.map(project => {
        return <div className={'w-full'} key={project.id}><ProjectLineItem project={project}/></div>
      }) : ('sections' in props) ? props.sections.map((section, i) => {
        return <div className={'w-full'} key={section.id}><SectionLineItem index={i} project={props.project} section={section}/></div>
      }) : props.texts.map(text => {
        return <div className={'w-full'} key={text.id}><TextLineItem text={text}/></div>
      })}
    </>
  )
}

export default LineListContainer
