import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import ActionMenuHeader from '../components/list/ActionMenuHeader'
import CardListContainer from '../components/list/CardListContainer'
import LineListContainer from '../components/list/LineListContainer'
import ListHeader from '../components/list/ListHeader'
import PartHeader from '../components/list/PartHeader'
import PageLoad from '../components/loading/PageLoad'
import { fetchHome } from '../repository/Fetch'
import { ProjectListItemData, TextListItemData } from '../repository/Models'
import { getSetting, saveSetting } from '../storage/Settings'
import { selectHome, setHome } from '../store/serverSlice'

interface State {
  home: {
    recentTexts: TextListItemData[]
    allProjects: ProjectListItemData[]
  }
}

const Home: React.FC = () => {
  const [viewCard, setViewCard] = useState(getSetting('home-view-project-list') === 'card')
  const dispatch = useAppDispatch()
  const state = useAppSelector(selectHome)
  if (!state) {
    return <PageLoad<State>
      fetch={accessToken => fetchHome(accessToken)}
      dispatch={response => dispatch(setHome(response))}
    />
  }
  return <>
    <div className={'fixed w-full h-48px border-b border-lightBorder dark:border-darkBorder bg-lightSurface dark:bg-darkSurface'}>
      <ActionMenuHeader mode={'home'} />
    </div>
    <div className={'mt-48px p-8px md:px-40px md:py-24px'}>
      <PartHeader type={'home'} />
      <CardListContainer oneline={true} texts={state.home.recentTexts} />
      <ListHeader
        title='すべてのプロジェクト'
        changeToListView={() => {
          saveSetting('home-view-project-list', 'list')
          setViewCard(false)
        }}
        changeToCardView={() => {
          saveSetting('home-view-project-list', 'card')
          setViewCard(true)
        }} />
      {viewCard
        ? <CardListContainer projects={state.home.allProjects} />
        : <LineListContainer projects={state.home.allProjects} />}
    </div>
  </>
}

export default Home
