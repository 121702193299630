import React from 'react'
import styled, { keyframes } from 'styled-components'

const loading = keyframes`
0% {
  transform: rotate(0deg);
  stroke-dasharray: 10px 245px;
}
75% {
  transform: rotate(270deg);
  stroke-dasharray: 250px 5px;
}
100% {
  transform: rotate(720deg);
  stroke-dasharray: 10px 245px;
}
`

const AnimatedSvg = styled.svg`
animation: ${loading} 2s linear infinite;
`

const LoadingIcon: React.FC<{
  size?: 'small'
}> = props => {
  return <AnimatedSvg
    className={props.size === 'small' ? 'w-24px h-24px' : 'w-48px h-48px'}
    viewBox={'0 0 100 100'}
  >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#496600" />
        <stop offset="100%" stopColor="#ccff00" />
      </linearGradient>
    </defs>
    <circle strokeWidth="8" fill="none" strokeLinecap="round" stroke="url(#gradient)" cx="50" cy="50" r="40"/>
  </AnimatedSvg>
}

export default LoadingIcon
