import React, { useRef } from 'react'

const InputModal: React.FC<{
  message: string
  placeholder?: string | undefined
  defaultValue?: string | undefined
  positive: {
    label: string
    onSubmit: (text: string) => void
  }
  negative: {
    label: string
    onClick: () => void
  }
}> = props => {
  const input = useRef<HTMLInputElement>(null)
  return <div className={'p-16px flex flex-col'}>
    <p className={'mb-16px'}>{props.message}</p>
    <form onSubmit={e => {
      e.preventDefault()
      props.positive.onSubmit(input.current?.value ?? '')
    }}>
      <input
        className={'w-full mb-16px border border-lightBorder dark:border-darkBorder bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary'}
        ref={input}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
      />
    </form>
    <div className={'flex justify-end'}>
      <button className={'btn-normal mr-8px'} onClick={props.negative.onClick}>{props.negative.label}</button>
      <button className={'btn-primary'} onClick={() => {
        if (input.current) {
          props.positive.onSubmit(input.current.value)
        }
      }}>{props.positive.label}</button>
    </div>
  </div>
}

export default InputModal
