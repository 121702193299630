import temporaryTextDatabase, { IdbTemporaryText } from './TemporaryTextDatabase'

export async function getIdbTemporaryText (id: number): Promise<IdbTemporaryText | undefined> {
  const db = await temporaryTextDatabase
  return db.get('temporaryText', id)
}

export async function deleteIdbTemporaryText (id: number): Promise<void> {
  const db = await temporaryTextDatabase
  return db.delete('temporaryText', id)
}

export async function putIdbTemporaryText (value: IdbTemporaryText): Promise<number> {
  const db = await temporaryTextDatabase
  return db.put('temporaryText', value)
}
