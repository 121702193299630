import React, { useContext, useRef, useState } from 'react'
import { Models } from '../../api/Models'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { fetchNextPath, fetchTextHistory, fetchTextSnapshot } from '../../repository/Fetch'
import { TextData } from '../../repository/Models'
import { dismissModal } from '../../store/modalSlice'
import { NetworkStoreContext } from '../../store/NetworkStore'
import { addTextHistory, selectTextHistory, selectTextHistorySnapshot, setTextHistory, setTextHistorySnapshot } from '../../store/serverSlice'
import { showToast } from '../../store/toastSlice'
import LoadingIcon from '../loading/LoadingIcon'
import { useLoading } from '../loading/PageLoad'

const TextHistorySnapshot: React.FC<{
  textId: number
  revisionNumber: number
  onClose: () => void
}> = props => {
  const dispatch = useAppDispatch()
  const snapshot = useAppSelector(state => selectTextHistorySnapshot(state, props.textId, props.revisionNumber))
  const [loadingStatus, setWaiting] = useLoading<{
    textSnapshot: Models.TextHistoryDetail
  }>({
    fetch: accessToken => fetchTextSnapshot(props.textId, props.revisionNumber, accessToken),
    dispatch: response => dispatch(setTextHistorySnapshot(response.textSnapshot))
  })
  const textarea = useRef<HTMLTextAreaElement>(null)
  if (!snapshot) {
    return <div className={'fixed top-0 left-0 w-full h-full flex flex-col'}>
      <div className={'flex justify-end m-8px'}>
        <button className={'btn-normal'} onClick={props.onClose}>閉じる</button>
      </div>
      <div className='flex justify-center items-center w-full flex-grow bg-lightSurface dark:bg-darkSurface'>
        {loadingStatus === 'loading'
          ? '読み込み中'
          : <button className={'min-w-widthButton m-16px px-16px py-8px bg-accent text-white font-bold rounded-4px pointer-events-auto'} onClick={setWaiting}>再読み込み</button>}
      </div>
    </div>
  }
  return <div className={'fixed top-0 left-0 w-full h-full flex flex-col'}>
    <div className={'flex justify-end m-8px'}>
      <button className={'btn-normal mr-8px'} onClick={() => {
        if (!textarea.current) {
          return
        }
        navigator.clipboard.writeText(textarea.current.value)
          .then(() => {
            dispatch(showToast({
              type: 'success',
              message: 'コピーしました'
            }))
          })
          .catch(() => {
            dispatch(showToast({
              type: 'warning',
              message: 'Command+C か Control+C をお使いください'
            }))
          })
      }}>コピー</button>
      <button className={'btn-normal'} onClick={props.onClose}>閉じる</button>
    </div>
    <div className='w-full bg-lightSurface dark:bg-darkSurface flex-grow flex'>
      <textarea ref={textarea} className='p-16px w-full flex-grow resize-none outline-none bg-lightSurface dark:bg-darkSurface' value={snapshot.textHistoryRawText} readOnly/>
    </div>
  </div>
}

const NextTextHistory: React.FC<{
  textId: number
  nextPath: string
}> = props => {
  const [isLoading, setLoading] = useState(false)
  const networkStore = useContext(NetworkStoreContext)
  const dispatch = useAppDispatch()
  return <button className={'btn-normal mr-8px'} onClick={() => {
    fetchNextPath<{
      textHistory: Models.TextHistory[]
      nextPath?: string
    }>(props.nextPath, networkStore)
      .then(response => dispatch(addTextHistory({
        id: props.textId,
        data: response
      })))
      .catch(() => {
        setLoading(false)
      })
  }} disabled={isLoading}>{isLoading ? <LoadingIcon size={'small'}/> : '次の10件'}</button>
}

const LoadTextHistory: React.FC<{
  text: TextData
}> = props => {
  const dispatch = useAppDispatch()
  const [loadingStatus, setWaiting] = useLoading<{
    textHistory: Models.TextHistory[]
    nextPath?: string
  }>({
    fetch: accessToken => fetchTextHistory(props.text.id, accessToken),
    dispatch: response => dispatch(setTextHistory({
      id: props.text.id,
      data: response
    }))
  })
  return <div className={'p-16px'}>
    <div className='flex justify-center items-center h-120px'>
      {loadingStatus === 'loading'
        ? '読み込み中'
        : <button className={'min-w-widthButton m-16px px-16px py-8px bg-accent text-white font-bold rounded-4px pointer-events-auto'} onClick={setWaiting}>再読み込み</button>}
    </div>
    <div className={'flex justify-end'}>
      <button className={'btn-normal'} onClick={() => dispatch(dismissModal())}>閉じる</button>
    </div>
  </div>
}

const TextHistoryModal: React.FC<{
  text: TextData
}> = props => {
  const dispatch = useAppDispatch()
  const history = useAppSelector(state => selectTextHistory(state, props.text.id))
  const [revisionNumber, setRevisionNumber] = useState<number | null>(null)
  if (!history || history.data.length === 0 || history.data[0].textHistoryRevisionNumber < props.text.revisionNumber) {
    return <LoadTextHistory text={props.text}/>
  }
  return <>
    <div className={'p-16px flex flex-col max-h-screen'}>
      <ul className={'-mx-8px mb-8px overflow-y-scroll shrink-1'}>
        {history.data.map(snapshot => {
          return <li key={snapshot.textHistoryRevisionNumber} className={'flex w-full items-center h-40px px-8px text-12px'}>
            <span className={'w-48px'}>{snapshot.textHistoryRevisionNumber === props.text.revisionNumber ? '(現在)' : `${snapshot.textHistoryRevisionNumber}版`}</span>
            <span className={'flex-grow'}>
              <span>{new Date(snapshot.textHistoryUserCreatedTimestamp).toLocaleDateString()}</span>
              <span className={'hidden md:inline'}> {new Date(snapshot.textHistoryUserCreatedTimestamp).toLocaleTimeString()}</span>
              <span> 保存</span>
            </span>
            <span className={'w-80px text-right'}>{snapshot.textHistoryCharacterCount.toLocaleString()}文字</span>
            <button className={'ml-8px px-8px py-4px rounded-4px bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary hover:shadow'} onClick={() => {
              setRevisionNumber(snapshot.textHistoryRevisionNumber)
            }}>詳細</button>
          </li>
        })}
      </ul>
      <div className={'flex justify-center shrink-0'}>
        {history.nextPath ? <NextTextHistory textId={props.text.id} nextPath={history.nextPath}/> : undefined}
        <button className={'btn-normal'} onClick={() => dispatch(dismissModal())}>閉じる</button>
      </div>
    </div>
    {revisionNumber ? <TextHistorySnapshot textId={props.text.id} revisionNumber={revisionNumber} onClose={() => setRevisionNumber(null)}/> : null}
  </>
}

export default TextHistoryModal
