import Header from './Header'
import SideMenu from './SideMenu'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { useLocation } from 'react-router-dom'
import Page from './Page'
import { closeSideMenu, selectIsOpened } from '../store/sideMenuSlice'
import ContextMenuStack from './stacks/ContextMenuStack'
import { selectHasLoggedIn } from '../store/userSlice'
import ModalStack from './stacks/ModalStack'
import ToastStack from './stacks/ToastStack'
import Head from './Head'

const Container: React.FC<{}> = () => {
  const hasLoggedIn = useAppSelector(selectHasLoggedIn)
  const isOpened = useAppSelector(selectIsOpened)
  const dispatch = useAppDispatch()
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!window.matchMedia('(min-width: 768px)').matches) {
      dispatch(closeSideMenu())
    }
  }, [location.pathname])
  return <>
    <Head/>
    <main className={`transition-container pl-0 ${hasLoggedIn && isOpened ? 'md:pl-widthSideMenu' : 'md:pl-0'} pt-heightHeader w-full min-h-screen bg-lightSurface dark:bg-darkSurface`}>
      <Page/>
    </main>
    <nav className={`fixed transition-container ${hasLoggedIn && isOpened ? 'left-0' : '-left-widthSideMenu'} top-heightHeader w-widthSideMenu h-heightMain border-r border-lightBorder dark:border-darkBorder bg-lightSurface dark:bg-darkSurface`}>
      <SideMenu/>
    </nav>
    <header className={'fixed top-0 left-0 w-full h-heightHeader border-b border-lightBorder dark:border-darkBorder bg-lightSurface dark:bg-darkSurface bg-opacity-80 dark:bg-opacity-80'}>
      <Header/>
    </header>
    <div className={`fixed top-0 left-widthSideMenu w-full h-full ${hasLoggedIn && isOpened ? 'md:hidden' : 'hidden'}`} onClick={() => dispatch(closeSideMenu())}/>
    <ContextMenuStack/>
    <ModalStack/>
    <ToastStack/>
  </>
}

export default Container
