import { deleteIdbTemporaryText, getIdbTemporaryText, putIdbTemporaryText } from '../database/temporaryText/TemporaryTextDao'
import { IdbTemporaryText } from '../database/temporaryText/TemporaryTextDatabase'

interface Draft {
  data: string
  savedTimestamp: number
}

function availableStorage (): Storage {
  const storage = localStorage ?? sessionStorage
  if (!storage) {
    throw new Error('No storage')
  }
  return storage
}

function save (draft: Draft) {
  const storage = availableStorage()
  storage.setItem('draft', JSON.stringify(draft))
}

function remove () {
  const storage = availableStorage()
  storage.removeItem('draft')
}

function get (): (Draft | null) {
  const storage = availableStorage()
  const jsonDraft = storage.getItem('draft')
  if (!jsonDraft) {
    return null
  }
  return JSON.parse(jsonDraft)
}

export function saveDraft (text: string) {
  try {
    const now = new Date()
    save({
      data: text,
      savedTimestamp: now.getTime()
    })
  } catch (error) {
    console.error(error)
  }
}

export function clearDraft () {
  try {
    remove()
  } catch (error) {
    console.error(error)
  }
}

export function getDraft (): (string | null) {
  try {
    return get()?.data ?? null
  } catch (error) {
    console.error(error)
    return null
  }
}

function saveTemporary (temp: IdbTemporaryText) {
  const storage = availableStorage()
  if (!storage) {
    return
  }
  storage.setItem('temporaryText', JSON.stringify(temp))
}

function removeTemporary () {
  const storage = availableStorage()
  if (!storage) {
    return
  }
  storage.removeItem('temporaryText')
}

function getTemporary (): (IdbTemporaryText | null) {
  const storage = availableStorage()
  if (!storage) {
    return null
  }
  const jsonDraft = storage.getItem('temporaryText')
  if (!jsonDraft) {
    return null
  }
  return JSON.parse(jsonDraft)
}

export async function migrateTemporaryText (): Promise<void> {
  const temporary = getTemporary()
  if (temporary) {
    await putIdbTemporaryText(temporary)
    removeTemporary()
  }
}

export async function saveTemporaryText (id: number, text: string): Promise<void> {
  const now = new Date()
  const temporaryText: IdbTemporaryText = {
    id: id,
    text: text,
    createdTimestamp: now.getTime(),
    updatedTimestamp: now.getTime()
  }
  saveTemporary(temporaryText)
  await putIdbTemporaryText(temporaryText)
  removeTemporary()
}

export async function clearTemporaryText (id: number): Promise<void> {
  return deleteIdbTemporaryText(id)
}

export async function getTemporaryText (id: number): Promise<string | null> {
  migrateTemporaryText()
  const temporaryText = await getIdbTemporaryText(id)
  return temporaryText?.text ?? null
}
