import { postFeedback } from '../api/ApiRequest'
import { NetworkStateReducer, updateTokenIfNeeded } from '../store/NetworkStore'

export async function sendFeedback (
  type: 'help' | 'feedback',
  subtype: string,
  message: string,
  network: NetworkStateReducer
): Promise<{}> {
  const accessToken = await updateTokenIfNeeded(network)
  return postFeedback(type, subtype, message, accessToken)
}
