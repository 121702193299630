import { DBSchema, openDB } from 'idb'

const databaseName = 'tateditor-help-history-database'
const databaseVersion = 1

export interface IdbHelp {
  id: string
  viewCount: number
  updatedTimestamp: number
  createdTimestamp: number
}

interface DatabaseSchema extends DBSchema {
  'helpHistory': {
    key: string
    value: IdbHelp
    indexes: {
      'index_updatedTimestamp': number
      'index_createdTimestamp': number
      'index_viewCount': number
    }
  }
}

const helpHistoryDatabase = openDB<DatabaseSchema>(databaseName, databaseVersion, {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  upgrade (db, oldVersion, newVersion, transaction) {
    const searchHistoryStore = db.createObjectStore('helpHistory', {
      keyPath: 'id'
    })
    searchHistoryStore.createIndex('index_updatedTimestamp', 'updatedTimestamp')
    searchHistoryStore.createIndex('index_createdTimestamp', 'createdTimestamp')
    searchHistoryStore.createIndex('index_viewCount', 'viewCount')
  },
  blocked () {
    //
  },
  blocking () {
    //
  },
  terminated () {
    //
  }
})

export default helpHistoryDatabase
