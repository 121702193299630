import { patchContent, putContent, putRefreshToken, putText } from '../api/ApiRequest'
import { Models } from '../api/Models'
import { NetworkStateReducer, updateTokenIfNeeded } from '../store/NetworkStore'
import { convertToSectionListItem, SectionListItemData, TextData } from './Models'

export async function updateText (
  text: TextData,
  document: string,
  network: NetworkStateReducer
): Promise<TextData> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await putText(text.id, document, accessToken)
  return {
    ...text,
    text: document,
    revisionNumber: result.text.textHistoryRevisionNumber,
    savedTimestamp: result.text.textUserUpdatedTimestamp,
    lineCount: result.text.textLineCount,
    characterCount: result.text.textCharacterCount
  }
}

export async function updateSectionOrder (
  id: number,
  targetDisplayOrder: number,
  network: NetworkStateReducer
): Promise<SectionListItemData> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await patchContent(id, targetDisplayOrder, accessToken)
  return convertToSectionListItem(result.content)
}

export async function updateContent (
  id: number,
  title: string,
  network: NetworkStateReducer
): Promise<Models.Content> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await putContent(id, title, accessToken)
  return result.content
}

export async function updateRefreshToken (
  id: number,
  expireMilliSeconds: number | undefined,
  network: NetworkStateReducer
): Promise<Models.RefreshToken> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await putRefreshToken(id, expireMilliSeconds, accessToken)
  return result.refreshToken
}
