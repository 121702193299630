import React from 'react'
import LoadingIcon from './LoadingIcon'

const LoadingUser: React.FC = () => {
  return <div className={'fixed top-0 left-0 w-full h-full flex justify-center items-center'}>
    <LoadingIcon/>
  </div>
}

export default LoadingUser
