import React from 'react'
import { AuthProvider, OAuthProvider, GoogleAuthProvider, getAuth, signInWithRedirect, browserLocalPersistence, browserSessionPersistence } from 'firebase/auth'
import { ReactComponent as Logo } from '../img/tateditor-icon.svg'
import { ReactComponent as LogoApple } from '../img/logo_apple_44.svg'
import { ReactComponent as LogoGoogle } from '../img/logo_google_18.svg'
import { ReactComponent as LogoMicrosoft } from '../img/logo_microsoft_21.svg'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../app/hooks'
import { AppDispatch } from '../app/store'
import { clearUserState, applyUserState } from '../store/userSlice'

async function login (provider: AuthProvider): Promise<void> {
  const persistence = document.getElementById('pesistence') as HTMLInputElement | null
  await getAuth().setPersistence(persistence?.checked ? browserLocalPersistence : browserSessionPersistence)
  await signInWithRedirect(getAuth(), provider)
}

function googleLogin (dispatch: AppDispatch): void {
  dispatch(clearUserState())
  login(new GoogleAuthProvider())
    .catch(() => dispatch(applyUserState(null)))
}

function microsoftLogin (dispatch: AppDispatch): void {
  dispatch(clearUserState())
  login(new OAuthProvider('microsoft.com'))
    .catch(() => dispatch(applyUserState(null)))
}

function appleLogin (dispatch: AppDispatch): void {
  dispatch(clearUserState())
  login(new OAuthProvider('apple.com'))
    .catch(() => dispatch(applyUserState(null)))
}

const SignInButton: React.FC<{
  onClick: React.MouseEventHandler<HTMLDivElement>
  children: React.ReactNode
}> = props => {
  return <div onClick={props.onClick} className={'flex shadow pl-8px items-center m-8px overflow-hidden rounded-4px bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary cursor-pointer h-40px w-240px border border-gray-500'}>
    {props.children}
  </div>
}

const SignIn: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  return (
    <div className={'min-h-heightMain flex flex-col items-center justify-around w-full bg-brand bg-opacity-10 bg-no-repeat bg-center bg-cover md:bg-contain bg-logo'}>
      <div className={'p-24px flex flex-col items-center max-w-widthPanel m-24px rounded-16px bg-lightSurface dark:bg-darkSurface shadow'}>
        <Logo className={'rounded-4px w-80px h-80px'}/>
        <h2 className={'mt-40px mb-32px text-40px'}>TATEditor</h2>
        <SignInButton onClick={() => appleLogin(dispatch)}><LogoApple style={{
          margin: '-2px'
        }}/><span>Appleで続ける</span></SignInButton>
        <SignInButton onClick={() => googleLogin(dispatch)}><LogoGoogle style={{
          margin: '11px'
        }}/><span>Googleで続ける</span></SignInButton>
        <SignInButton onClick={() => microsoftLogin(dispatch)}><LogoMicrosoft style={{
          margin: '9.5px'
        }}/><span>Microsoftで続ける</span></SignInButton>
        <div className={'flex mt-32px items-center'}>
          <input type="checkbox" id="pesistence" defaultChecked/>
          <label htmlFor="pesistence">ログイン状態を保持する</label>
        </div>
        <p className={'mt-32px text-12px'}>上のボタンをクリックすることで、<Link className={'link'} to={'/tos'}>利用規約</Link>、<Link className={'link'} to={'/privacy'}>プライバシーポリシー</Link>に同意するものとします。</p>
      </div>
    </div>
  )
}

export default SignIn
