import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { dismissModal, ModalProps, selectModalProps } from '../../store/modalSlice'

const Modal: React.FC<{
  modalProps: ModalProps | undefined
}> = props => {
  if (!props.modalProps) {
    return null
  }
  return <div className={'shadow fixed bg-lightSurface dark:bg-darkSurface w-widthModal max-w-widthModal rounded-8px'} onClick={e => {
    e.stopPropagation()
  }}>
    <props.modalProps.component/>
  </div>
}

const ModalStack: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(selectModalProps)
  return <div className={`transition-all fixed flex justify-center items-center top-0 left-0 w-full h-full ${modalProps ? 'pointer-events-auto bg-black bg-opacity-30' : 'pointer-events-none'}`} onClick={() => dispatch(dismissModal())}>
    <Modal modalProps={modalProps}/>
  </div>
}

export default ModalStack
