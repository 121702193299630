import { User } from 'firebase/auth'
import { createRequest, requestDelete } from './ApiRequest'

export async function deleteMe (user: User): Promise<{}> {
  const token = await user.getIdToken()
  return requestDelete('/me', token)
}

export async function postToken (user: User): Promise<{
  accessToken: string
}> {
  return new Promise((resolve, reject) => {
    user.getIdToken()
      .then(token => {
        createRequest(token)
          .post(
            '/token',
            { name: null }
          )
          .then(res => resolve(res.data))
          .catch(reject)
      })
      .catch(reject)
  })
}
