import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const TermHeader: React.FC<{ children: React.ReactNode }> = props => {
  return <h3 className={'mt-32px mb-16px text-16px md:text-24px'}>{props.children}</h3>
}

const StyledListItem = styled.li`
&:before {
  content: '・'
}
`

const TermItem: React.FC<{ children: React.ReactNode }> = props => {
  return <StyledListItem>{props.children}</StyledListItem>
}

const Tos: React.FC<{}> = () => {
  return (
    <div className={'font-mincho p-24px md:p-96px leading-document'}>
      <h2 className={'mb-16px text-24px md:text-40px'}>利用規約</h2>
      <p>この利用規約（以下「本規約」といいます。）は、TATEditor（以下、本サービスといいます。）と本サービスの利用者（以下「ユーザー」といいます。）の間のサービス利用にまつわる条件を定めるものです。</p>
      <p>ユーザーは本規約の条件に同意の上、本規約に従い本サービスを利用するものとします。</p>

      <TermHeader>1. 適用範囲</TermHeader>
      <p>本規約は、ユーザーと本サービスとの間の本サービスの利用または閲覧に関わる一切の関係に適用されるものとします。</p>

      <TermHeader>2. 個人情報の取扱</TermHeader>
      <p>本サービスはユーザーの個人情報を本サービスの「<Link to={'/privacy'}>プライバシーポリシー</Link>」に基づき、適切に取り扱うものとします。</p>

      <TermHeader>3. アカウント登録等</TermHeader>
      <p>本サービスにはアカウントが必要になる機能やコンテンツがあります。</p>
      <p>アカウントを希望するユーザーは、本規約に同意の上、本サービスの定める方法によってアカウント登録を申請するものとします。</p>
      <p>アカウント登録の申請に際してユーザーは、以下よりアカウントへのログイン方法（以下、認証情報といいます。）を選択するものとします。</p>
      <ul>
        <TermItem>Appleアカウントによるログイン</TermItem>
        <TermItem>Googleアカウントによるログイン</TermItem>
        <TermItem>Microsoftアカウントによるログイン</TermItem>
      </ul>
      <p>アカウント登録は、本サービスがユーザーのアカウント登録の申請を承認し、新規アカウントを発行することによって完了するものとします。</p>
      <p>本サービスは、アカウント登録の申請者に以下の事由があると判断した場合、アカウント登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。</p>
      <ul>
        <TermItem>本規約に違反したことがある者からの申請である場合</TermItem>
        <TermItem>本サービスの定める方法によらずアカウント登録を申請した場合</TermItem>
        <TermItem>その他、本サービスがアカウント登録を相当でないと判断した場合</TermItem>
      </ul>
      <p>アカウントの削除（以下、退会といいます。）を希望するユーザーは、本サービスの定める方法によって退会を申請するものとします。</p>
      <p>退会は、本サービスがユーザーの退会の申請を受理し、本サービスから当該認証情報との連携を解除することによって完了するものとします。</p>

      <TermHeader>4. 認証情報の管理</TermHeader>
      <p>ユーザーは、いかなる場合にも、認証情報を第三者に譲渡または貸与し、もしくは第三者と共同で使用することはできません。</p>
      <p>認証情報を第三者によって使用されたことによって生じた損害について、本サービスに故意又は重大な過失がある場合を除き、本サービスは一切の責任を負いません。</p>
      <p>本サービスは、認証情報を利用して行われたすべての行為を当該認証情報を登録したユーザー自身による行為とみなします。ユーザーは、当該行為に関連して生じる一切の責任を負うことに同意するものとし、自己の責任において、本サービスの認証情報を適切に管理するものとします。</p>
      <p>本サービスは、認証情報の停止または削除など、認証情報の管理者の都合により生じるユーザーの不利益については一切の責任を負いません。</p>

      <TermHeader>5. 利用環境</TermHeader>
      <p>本サービスを利用するために必要なあらゆるハードウェア・ソフトウェア等および通信手段の整備、ならびに、認証情報の取得はユーザーの負担と責任において行うものとします。</p>
      <p>ユーザーの利用環境に起因する本サービスの利用不能による責任および損害は、ユーザーが負うものとし、本サービスはユーザーの故意や過失の有無を問わず、責任を負いません。</p>

      <TermHeader>6. コンテンツの取扱</TermHeader>
      <p>ユーザーが本サービス上で作成（アップロードを含む。）したコンテンツ（文章、画像、動画その他のデータを含むがこれらに限らない。）に関しての一切の責任は、そのコンテンツに紐付くアカウントを所有するユーザーが負います。</p>
      <p>ユーザーは作成したコンテンツについて、自らが取り扱うことについての適法な権利を有していること、及びコンテンツが第三者の権利を侵害していないことについて、本サービスに対し表明し、保証するものとします。</p>
      <p>ユーザーが作成したコンテンツが第三者の権利を侵害するなど第三者との間で何らかの紛争が発生した場合には、当該ユーザーの費用と責任において問題を解決するとともに、本サービスに何等の損害を与えないものとします。</p>
      <p>ユーザーが作成したコンテンツの著作権については、当該ユーザーその他既存の権利者に留保されるものとします。</p>
      <p>本サービスは、ユーザーが作成したコンテンツを検閲することはありませんが、以下の場合に限り、必要な範囲でコンテンツにアクセスを行うことができるものとします。</p>
      <ul>
        <TermItem>法令に基づく場合</TermItem>
        <TermItem>ユーザーの同意があった場合</TermItem>
        <TermItem>不具合対応又はサービス変更等のため必要な場合</TermItem>
        <TermItem>本サービスの運営上必要となる統計的データを作成する場合</TermItem>
        <TermItem>本規約に定める禁止事項に該当する行為が行われた可能性がある場合</TermItem>
      </ul>
      <p>本サービスは、以下の場合に限り、例外的に必要な範囲でコンテンツに変更または削除を行うことができるものとします。</p>
      <ul>
        <TermItem>法令に基づく場合</TermItem>
        <TermItem>ユーザーの同意があった場合</TermItem>
        <TermItem>不具合対応又はサービス変更等のため必要な場合</TermItem>
        <TermItem>本規約に定める禁止事項に該当する行為が行われた場合</TermItem>
      </ul>
      <p>本サービスは、プライバシーポリシーに定められている場合を除き、コンテンツを第三者に提供することはありません。</p>
      <p>ユーザーは、ユーザーが本サービス上に作成したコンテンツについて、本サービスが保存の義務を負わないことを承諾するものとします。</p>

      <TermHeader>7. ユーザーの責任</TermHeader>
      <p>ユーザーは、ユーザーの自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為及びその結果について一切の責任を負います。</p>
      <p>ユーザーは、本サービスがコンテンツの保存の義務を負わないことを認識し、ユーザーの責任においてコンテンツを管理するものとします。</p>

      <TermHeader>8. 知的財産権</TermHeader>
      <p>本サービスに関する知的財産権は全て本サービスまたは本サービスにその利用を許諾した権利者に帰属しており、本規約に基づく本サービスの利用許諾は、本サービスに関する本サービスまたは本サービスにその利用を許諾した権利者の知的財産権の使用許諾を意味するものではありません。</p>

      <TermHeader>9. 倫理規定</TermHeader>
      <p>ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると本サービスが判断する行為をしてはなりません。</p>
      <ul>
        <TermItem>法令に違反する行為または犯罪行為に関連する行為</TermItem>
        <TermItem>本サービスの関係者、本サービスの他のユーザー、または第三者に対する詐欺または脅迫行為</TermItem>
        <TermItem>本サービスの関係者、本サービスの他のユーザー、または第三者に不利益、損害または不快感を与えることを目的とする行為</TermItem>
        <TermItem>本サービスもしくは第三者の著作権、意匠権等の知的財産権、その他の権利を侵害する行為、または侵害するおそれのある行為</TermItem>
        <TermItem>本サービス、本サービスの他のユーザー、または第三者のサーバーまたはネットワークの機能に損害を与える行為</TermItem>
        <TermItem>本サービスの運営を妨害するおそれのある行為</TermItem>
        <TermItem>本サービスの他のユーザーに関する個人情報等を収集または蓄積する行為</TermItem>
        <TermItem>本サービスの関係者、本サービスの他のユーザー、または第三者に成りすます行為</TermItem>
        <TermItem>不正アクセスをし、またはこれを試みる行為</TermItem>
        <TermItem>本サービスが予定している利用目的と異なる目的で本サービスを利用する行為</TermItem>
        <TermItem>本サービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</TermItem>
        <TermItem>本サービスのすべてまたは一部の機能を、他のサービス等の機能と称して利用し、または組み込む行為（本サービスが特に認めたものを除く。）</TermItem>
        <TermItem>その他、本サービスが不適切と判断する行為</TermItem>
      </ul>

      <TermHeader>10. 免責事項</TermHeader>
      <p>本サービスは、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。本サービスは、ユーザーに対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。</p>
      <p>本サービスは、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する本サービスとユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、本項は適用されません。</p>
      <p>前項ただし書に定める場合であっても、本サービスは、本サービスの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（本サービスまたはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、本サービスの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した代金等の額を上限とします。</p>
      <p>本サービスは、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。</p>
      <p>本規約に定める本サービスの免責に関する条項にかかわらず、本サービスに関する本サービスとユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、本サービスの免責の内容は以下の各号に定めるとおりとします。</p>
      <ul>
        <TermItem>本サービスは、本サービスの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（本サービスまたはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について責任を負いません。</TermItem>
        <TermItem>本サービスは、本サービスの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち、ユーザーに現実に生じた通常かつ直接の範囲の損害の範囲内で、かつ、代金等が必要な機能の場合は当該ユーザーが当該損害が発生する前の１年間に本サービスに支払った代金等の総額を上限として、それ以外の場合は1000円を上限としてこれを賠償します。</TermItem>
      </ul>
      <p>本サービスは、前項に定める場合以外の場合（消費者契約以外の場合）であって、ユーザーによる本サービスの利用に関連してユーザーに対して万一何らかの理由で責任を負う場合には、ユーザーに現実に生じた通常かつ直接の範囲の損害の範囲内で、かつ、代金等が必要な機能の場合は当該ユーザーが損害賠償を請求する前の１年間に本サービスに支払った代金等の総額を上限として、これを賠償します。</p>

      <TermHeader>11. サービスの提供</TermHeader>
      <p>本サービスは、ユーザーに事前に通知することなく、本サービスの内容もしくは利用条件を変更し、または本サービスのすべてもしくは一部の提供を中止することができるものとします。</p>
      <p>本サービスは、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく、本サービスのすべてまたは一部の提供を停止または中断することができるものとします。</p>
      <ul>
        <TermItem>本サービスにかかる設備やシステム等の障害等が発生した場合</TermItem>
        <TermItem>本サービスにかかる設備やシステム等の保守またはメンテナンス等を行う場合</TermItem>
        <TermItem>本サービスの継続にセキュリティ上の問題が生じた場合</TermItem>
        <TermItem>火災、停電等により本サービスの提供ができなくなった場合</TermItem>
        <TermItem>戦争、動乱、暴動、騒乱等により本サービスの提供ができなくなった場合</TermItem>
        <TermItem>地震、噴火、洪水、津波等の天災により本サービスの提供ができなくなった場合</TermItem>
        <TermItem>その他、運用上または技術上本サービスの一時的な中断が必要と判断した場合</TermItem>
      </ul>
      <p>本サービスは、本サービスの提供の中止、停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。</p>

      <TermHeader>12. 利用制限および登録抹消</TermHeader>
      <p>本サービスは、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、データを削除し、ユーザーに対して本サービスのすべてもしくは一部の利用を制限しまたはアカウント登録を抹消することができるものとします。</p>
      <ul>
        <TermItem>法令または本規約に違反した場合</TermItem>
        <TermItem>アカウントに虚偽の事実があることが判明した場合</TermItem>
        <TermItem>本サービスからの連絡に対し、一定期間返答がない場合</TermItem>
        <TermItem>本サービスについて、最終の利用から一定期間利用がない場合</TermItem>
        <TermItem>その他、本サービスが本サービスの利用を適当でないと判断した場合</TermItem>
      </ul>
      <p>本サービスは、本条に基づき本サービスが行った行為によりユーザーに生じた損害について、一切の責任を負いません。</p>

      <TermHeader>13. 本規約上の地位の譲渡等</TermHeader>
      <p>ユーザーは、本サービスとの書面による事前の承諾なく、本規約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</p>
      <p>本サービスは、本サービスのすべてまたは一部を第三者に譲渡した場合には、当該サービス譲渡に伴い本規約上の地位、本規約に基づく権利及び義務並びにユーザーのアカウント情報その他のデータを当該譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。</p>

      <TermHeader>14. 本規約の変更</TermHeader>
      <p>本サービスは、それが本サービスに必要かつ合理的であると判断した場合には、いつでも本規約を変更することができるものとします。</p>
      <p>本サービスは、本規約を変更した場合には、これを本サービスが定める方法によりユーザーへ通知します。</p>
      <p>ユーザーが本規約の変更後、本サービスの利用を継続した場合には、当該ユーザーは変更後の規約に同意したものとみなします。</p>

      <TermHeader>15. 本規約の有効性</TermHeader>
      <p>本規約のいずれかの条項またはその一部が、法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>

      <TermHeader>16. 準拠法</TermHeader>
      <p>本規約は日本語を正文とし、本規約の解釈及び適用は日本国法に準拠するものとします。また、本規約に関するすべての紛争については、東京地方裁判所を第一審の専属管轄裁判所とします。</p>

      <TermHeader>附則</TermHeader>
      <p>本規約は、2020年11月24日から施行します。</p>
      <p>本規約の施行前にユーザーが行った行為についても本規約が適用されます。</p>

      <TermHeader>改訂</TermHeader>
      <p>2020年11月28日 ログイン方法を追加しました。</p>
      <p>2020年12月5日 退会申請について追加しました。</p>
    </div>
  )
}

export default Tos
