import searchHistoryDatabase, { IdbSearch } from './SearchHistoryDatabase'

export async function getAllIdbSearch (): Promise<IdbSearch[]> {
  const db = await searchHistoryDatabase
  const history = await db.getAllFromIndex('searchHistory', 'index_updatedTimestamp')
  return history.reverse()
}

export async function deleteIdbSearch (word: string): Promise<void> {
  const db = await searchHistoryDatabase
  return db.delete('searchHistory', word)
}

export async function putIdbSearch (value: IdbSearch): Promise<string> {
  const db = await searchHistoryDatabase
  return db.put('searchHistory', value)
}
