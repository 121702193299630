const firebaseConfig = {
  apiKey: 'AIzaSyAjvgq94C7lQ7wh562fgrci0PpGvzv8TZk',
  authDomain: 'tateditor.app',
  databaseURL: 'https://project-8757985899964272249.firebaseio.com',
  projectId: 'project-8757985899964272249',
  storageBucket: 'project-8757985899964272249.appspot.com',
  messagingSenderId: '591670508299',
  appId: '1:591670508299:web:3075296ebca5fe693d800e',
  measurementId: 'G-M5EBCFJ0FV'
}

export default firebaseConfig
