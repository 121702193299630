import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Models } from '../api/Models'
import PageLoad from '../components/loading/PageLoad'
import { fetchMe } from '../repository/Fetch'
import { useAppDispatch } from '../app/hooks'
import { AppDispatch } from '../app/store'
import { dismissModal, showModal } from '../store/modalSlice'
import AlertModal from '../components/modals/AlertModal'
import { deleteMe } from '../api/AuthRequest'
import { showToast } from '../store/toastSlice'
import { getAuth, signOut, User } from 'firebase/auth'

function deleteAccount (user: User, dispatch: AppDispatch) {
  deleteMe(user)
    .then(() => {
      signOut(getAuth())
      dispatch(showToast({
        type: 'success',
        message: 'アカウントの削除が完了しました'
      }))
    })
    .catch(() => dispatch(showToast({
      type: 'error',
      message: '通信に失敗しました'
    })))
}

function withdraw (user: User, dispatch: AppDispatch) {
  user.reload()
    .then(() => {
      dispatch(showModal({
        component () {
          return <AlertModal
            message={'アカウントを削除しますか？'}
            positive={{
              label: '削除する',
              onClick: () => {
                dispatch(dismissModal())
                if (confirm('本当に削除しますか？')) {
                  deleteAccount(user, dispatch)
                }
              }
            }}
            negative={{
              label: 'キャンセル',
              onClick: () => dispatch(dismissModal())
            }}
          />
        }
      }))
    })
}

interface State {
  me: Models.Me
}

const Me: React.FC<{}> = () => {
  const user = getAuth().currentUser
  const dispatch = useAppDispatch()
  const [state, setState] = useState<State | null>(null)
  if (!state || !user) {
    return <PageLoad<State>
      fetch={accessToken => fetchMe(accessToken)}
      dispatch={response => setState(response)}
    />
  }
  return <div className={'min-h-heightMain flex flex-col items-center justify-around w-full bg-brand bg-opacity-10 bg-no-repeat bg-center bg-cover md:bg-contain bg-logo'}>
    <div className={'p-24px flex flex-col items-center w-widthPanel max-w-widthPanel m-24px rounded-16px shadow bg-lightSurface dark:bg-darkSurface'}>
      <h2 className={'w-full text-24px'}>アカウント情報</h2>
      <dl className={'w-full grid grid-cols-2 my-40px mx-8px'}>
        <dt className={'my-12px'}>ユーザーID</dt>
        <dd className={'my-12px text-right'}>{state.me.userId}</dd>

        <dt className={'my-12px'}>メールアドレス</dt>
        <dd className={'my-12px text-right'}>{user.email ?? '(なし)'}</dd>

        <dt className={'my-12px'}>登録日</dt>
        <dd className={'my-12px text-right'}>{new Date(state.me.userCreatedTimestamp).toLocaleDateString()}</dd>

        <dt className={'my-12px'}>プロジェクト数</dt>
        <dd className={'my-12px text-right'}>{state.me.rootContentCount - state.me.rootTextContentCount}</dd>

        <dt className={'my-12px'}>短編数</dt>
        <dd className={'my-12px text-right'}>{state.me.rootTextContentCount}</dd>

        <dt className={'my-12px'}>テキスト数</dt>
        <dd className={'my-12px text-right'}>{state.me.textCount}</dd>
      </dl>
      <div className={'text-12px'}>
        <Link to={'/contact'}>ヘルプ</Link>・<Link to={'/auth/credentials'}>アクセス権限の管理</Link>・<button onClick={() => withdraw(user, dispatch)}>退会</button>
      </div>
    </div>
  </div>
}

export default Me
