import { Models } from '../api/Models'

interface BaseText {
  id: number
  savedTimestamp: number
  createdTimestamp: number
  lineCount: number
  characterCount: number
}

interface BaseContent {
  id: number
  title: string | null
  createdTimestamp: number
}

export interface TextListItemData extends BaseText {
  content: {
    id: number
    title: string | null
  }
  project: {
    id: number
    title: string | null
    edge: {
      displayNumber: number
    }
  } | null
}

export interface TextData extends TextListItemData {
  revisionNumber: number
  text: string
}

export interface ProjectListItemData extends BaseContent {
  savedTimestamp: number
  characterCount: number
  lineCount: number
  textCount: number
  text: BaseText | null
}

export interface TextSectionListItemData extends BaseContent {
  displayNumber: number
  text: BaseText
}

export interface EmptySectionListItemData extends BaseContent {
  displayNumber: null
  text: null
}

export type SectionListItemData = TextSectionListItemData | EmptySectionListItemData

export function convertToTextListItem (text: Models.TextContent): TextListItemData {
  return {
    id: text.textId,
    savedTimestamp: text.textUserUpdatedTimestamp,
    createdTimestamp: text.textUserCreatedTimestamp,
    lineCount: text.textLineCount,
    characterCount: text.textCharacterCount,
    content: {
      id: text.contentId,
      title: text.contentTitle ?? null
    },
    project: (text.contentRootId != null && text.contentEdgeDisplayNumber != null) ? {
      id: text.contentRootId,
      title: text.contentRootTitle ?? null,
      edge: {
        displayNumber: text.contentEdgeDisplayNumber
      }
    } : null
  }
}

export function convertToProjectListItem (project: Models.ContentRoot): ProjectListItemData {
  return {
    id: project.contentId,
    title: project.contentTitle ?? null,
    savedTimestamp: Math.max(project.contentUserUpdatedTimestamp, project.contentRootUserUpdatedTimestamp),
    createdTimestamp: project.contentRootUserUpdatedTimestamp,
    characterCount: project.contentRootTotalCharacterCount,
    lineCount: project.contentRootTotalLineCount,
    textCount: project.contentRootTotalTextCount,
    text: (project.textId != null && project.textUserUpdatedTimestamp != null && project.textUserCreatedTimestamp != null && project.textLineCount != null && project.textCharacterCount != null) ? {
      id: project.textId,
      savedTimestamp: project.textUserUpdatedTimestamp,
      createdTimestamp: project.textUserCreatedTimestamp,
      lineCount: project.textLineCount,
      characterCount: project.textCharacterCount
    } : null
  }
}

export function convertToSectionListItem (section: Models.ContentEdge): SectionListItemData {
  if (section.contentEdgeDisplayNumber != null && section.textId != null && section.textUserUpdatedTimestamp != null && section.textUserCreatedTimestamp != null && section.textLineCount != null && section.textCharacterCount != null) {
    return {
      id: section.contentId,
      displayNumber: section.contentEdgeDisplayNumber ?? null,
      title: section.contentTitle ?? null,
      createdTimestamp: section.contentUserCreatedTimestamp,
      text: {
        id: section.textId,
        lineCount: section.textLineCount,
        characterCount: section.textCharacterCount,
        savedTimestamp: section.textUserUpdatedTimestamp,
        createdTimestamp: section.textUserCreatedTimestamp
      }
    }
  } else {
    return {
      id: section.contentId,
      displayNumber: null,
      title: section.contentTitle ?? null,
      createdTimestamp: section.contentUserCreatedTimestamp,
      text: null
    }
  }
}

export function convertToText (text: Models.TextDetail & Models.RevisionNumber): TextData {
  return {
    ...convertToTextListItem(text),
    text: text.textRawText,
    revisionNumber: text.textHistoryRevisionNumber
  }
}

export function titleOfProject (project: ProjectListItemData): string {
  if (project.text) {
    return project.title ?? '無題のテキスト'
  }
  return project.title ?? '無題のプロジェクト'
}

export function titleOfSection (section: SectionListItemData): string {
  if (section.text) {
    return section.title ?? '無題のテキスト'
  }
  return section.title ?? '無題'
}

export function titleOfText (text: TextListItemData): string {
  return text.content.title ?? '無題のテキスト'
}

export function textCountOfProject (project: ProjectListItemData): string {
  if (project.text) {
    return '短編'
  }
  return `${project.textCount}話`
}

export function parentTitleOfText (text: TextListItemData): string {
  if (!text.project) {
    return '短編'
  }
  return text.project.title ?? '無題のプロジェクト'
}

export function subtitleOfText (text: TextListItemData): string {
  if (!text.project) {
    return '短編'
  }
  return `${text.project.title ?? '無題のプロジェクト'} #${text.project.edge.displayNumber}`
}

export function displayNumberOfText (text: TextListItemData): string {
  if (!text.project) {
    return '短編'
  }
  return `#${text.project.edge.displayNumber}`
}

export function displayNumberOfSection (section: SectionListItemData): string {
  if (section.displayNumber === null) {
    return ''
  }
  return `#${section.displayNumber}`
}

export function updateTimeOfText (text: TextListItemData): string {
  return new Date(text.savedTimestamp).toLocaleString() + '更新'
}

export function updateTimeOfProject (project: ProjectListItemData): string {
  return new Date(project.savedTimestamp).toLocaleString() + '更新'
}

export function updateTimeOfSection (section: SectionListItemData): string {
  if (!section.text) {
    return ''
  }
  return new Date(section.text.savedTimestamp).toLocaleString() + '更新'
}

export function characterCountOfText (text: TextListItemData): string {
  return text.characterCount.toLocaleString() + '文字'
}

export function characterCountOfProject (project: ProjectListItemData): string {
  return project.characterCount.toLocaleString() + '文字'
}

export function characterCountOfSection (section: SectionListItemData): string {
  if (!section.text) {
    return ''
  }
  return section.text.characterCount.toLocaleString() + '文字'
}
