import React, { useContext, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { feedback } from '../../app/server'
import { dismissModal } from '../../store/modalSlice'
import { NetworkStoreContext } from '../../store/NetworkStore'
import { selectHasLoggedIn } from '../../store/userSlice'

const FeedbackModal: React.FC<{}> = () => {
  const hasLoggedIn = useAppSelector(selectHasLoggedIn)
  const networkStore = useContext(NetworkStoreContext)
  const dispatch = useAppDispatch()
  const textArea = useRef<HTMLTextAreaElement | null>(null)
  return <div className={'p-16px'}>
    <h2 className={'mb-8px'}>フィードバック</h2>
    <textarea ref={textArea} className={'mb-8px w-full border h-120px border-lightBorder dark:border-darkBorder bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary'} placeholder={'フィードバックを入力'}/>
    <p className={'mb-8px text-12px'}>フィードバックへの返信は行っていませんのでご了承ください。</p>
    <div className={'flex justify-end'}>
      <button className={'btn-normal mr-8px'} onClick={() => {
        dispatch(dismissModal())
      }}>キャンセル</button>
      <button className={'btn-primary'} onClick={() => {
        feedback('feedback', 'none', textArea.current?.value ?? '', dispatch, hasLoggedIn, networkStore)
      }}>送信</button>
    </div>
  </div>
}

export default FeedbackModal
