import styled from 'styled-components'

const CardContainer = styled.div`
margin: 8px;
width: calc(100% - 16px);
@media (min-width: 640px) {
  width: calc(100% / 2 - 16px);
  &.oneline {
    display: none;
    &:nth-child(1) {
      display: initial;
    }
    &:nth-child(2) {
      display: initial;
    }
  }
}
@media (min-width: 768px) {
  width: calc(100% - 16px);
}
@media (min-width: 840px) {
  width: calc(100% / 2 - 16px);
  &.oneline {
    display: none;
    &:nth-child(1) {
      display: initial;
    }
    &:nth-child(2) {
      display: initial;
    }
  }
}
@media (min-width: 1080px) {
  width: calc(100% / 3 - 16px);
  &.oneline {
    display: none;
    &:nth-child(1) {
      display: initial;
    }
    &:nth-child(2) {
      display: initial;
    }
    &:nth-child(3) {
      display: initial;
    }
  }
}
`

export default CardContainer
