import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { dismissContextMenu, selectContextMenuProps } from '../../store/contextMenuSlice'

const ContextMenu: React.FC<{
  position: {
    clientX: number
    clientY: number
  }
  children: React.ReactNode
}> = props => {
  const style: React.CSSProperties = useMemo(() => {
    const position = {
      left: `${props.position.clientX}px`,
      top: `${props.position.clientY}px`
    }
    const overWidth = props.position.clientX + 200 > window.innerWidth
    const overHeight = props.position.clientY + 200 > window.innerHeight
    if (overWidth && overHeight) {
      return {
        ...position,
        transform: 'translateY(-100%) translateX(-100%)'
      }
    } else if (overWidth) {
      return {
        ...position,
        transform: 'translateX(-100%)'
      }
    } else if (overHeight) {
      return {
        ...position,
        transform: 'translateY(-100%)'
      }
    } else {
      return position
    }
  }, [props.position])
  return <ul
    className={'shadow fixed bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary p-8px w-widthContextMenu rounded-8px'}
    style={style}
  >
    {props.children}
  </ul>
}

const ContextMenuItem: React.FC<{
  label: string
  onClick: React.MouseEventHandler<HTMLLIElement>
}> = props => {
  return <li className={'block p-8px w-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={props.onClick}>
    {props.label}
  </li>
}

const ContextMenuLinkItem: React.FC<{
  label: string
  path: string
}> = props => {
  return <li>
    <Link className={'block p-8px w-full hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} to={props.path}>{props.label}</Link>
  </li>
}

const ContextMenuStack: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const contextMenuProps = useAppSelector(selectContextMenuProps)
  return <div className={`fixed top-0 left-0 w-full h-full ${contextMenuProps ? 'pointer-events-auto' : 'pointer-events-none'}`} onClick={() => dispatch(dismissContextMenu())}>
    {contextMenuProps ? <ContextMenu position={contextMenuProps.position}>
      {contextMenuProps.items.map((item, i) => {
        if ('path' in item) {
          return <ContextMenuLinkItem key={i} {...item}/>
        }
        return <ContextMenuItem key={i} {...item}/>
      })}
    </ContextMenu> : undefined}
  </div>
}

export default ContextMenuStack
