import React, { useContext, useMemo } from 'react'
import { ReactComponent as AddIcon } from 'material-design-icons/content/svg/production/ic_add_24px.svg'
import { ReactComponent as PublishIcon } from 'material-design-icons/editor/svg/production/ic_publish_24px.svg'
import { ProjectListItemData } from '../../repository/Models'
import { useAppDispatch } from '../../app/hooks'
import { showContextMenu } from '../../store/contextMenuSlice'
import { NetworkStoreContext } from '../../store/NetworkStore'
import { showModal } from '../../store/modalSlice'
import { useHistory } from 'react-router-dom'
import DropZoneModal from '../modals/DropZoneModal'
import { showNewProjectModal, showNewTextSectionModal, showUploadTextModal } from '../../app/server'

export interface ActionMenuProps {
  mode: 'home' | 'textList' | {
    project: ProjectListItemData
  }
}

const ActionMenuHeader: React.FC<ActionMenuProps> = props => {
  const dispatch = useAppDispatch()
  const networkStore = useContext(NetworkStoreContext)
  const history = useHistory()
  const onDrop = useMemo<(files: File[]) => void>(() => {
    return (files) => {
      if (files.length < 1) {
        return
      }
      const file = files[0]
      const project = (props.mode === 'home' || props.mode === 'textList') ? undefined : props.mode.project
      showUploadTextModal(dispatch, networkStore, file, project, result => history.push(`/texts/${result.id}/edit`))
    }
  }, [dispatch, history, networkStore, props.mode])
  const onNew = useMemo<(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void>(() => {
    if (props.mode !== 'home' && props.mode !== 'textList') {
      const project = props.mode.project
      return () => {
        showNewTextSectionModal(dispatch, networkStore, project, result => history.push(`/texts/${result.id}/edit`))
      }
    } else {
      return e => {
        const rect = e.currentTarget.getBoundingClientRect()
        dispatch(showContextMenu({
          position: {
            clientX: rect.left,
            clientY: rect.top - 4
          },
          items: [
            {
              label: '新規テキスト',
              path: '/new'
            },
            {
              label: '新規プロジェクト',
              onClick () {
                showNewProjectModal(dispatch, networkStore, result => history.push(`/projects/${result.id}`))
              }
            }
          ]
        }))
      }
    }
  }, [dispatch, history, networkStore, props.mode])
  return <ul className={'ml-8px h-full flex items-center align-bottom'}>
    <li className={'h-full px-16px flex items-center align-bottom hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'} onClick={onNew}>
      <AddIcon className={'mr-8px'}/>新規作成
    </li>
    <li className={'h-full px-16px flex items-center align-bottom hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'} onClick={() => {
      dispatch(showModal({
        component () {
          return <DropZoneModal onDrop={onDrop}/>
        }
      }))
    }}>
      <PublishIcon className={'mr-8px'}/>アップロード
    </li>
  </ul>
}

export default ActionMenuHeader
