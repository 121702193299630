import { DBSchema, openDB } from 'idb'

const databaseName = 'tateditor-temporary-text-database'
const databaseVersion = 1

export interface IdbTemporaryText {
  id: number
  text: string
  updatedTimestamp: number
  createdTimestamp: number
}

interface DatabaseSchema extends DBSchema {
  'temporaryText': {
    key: number
    value: IdbTemporaryText
    indexes: {
      'index_updatedTimestamp': number
      'index_createdTimestamp': number
    }
  }
}

const temporaryTextDatabase = openDB<DatabaseSchema>(databaseName, databaseVersion, {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  upgrade (db, oldVersion, newVersion, transaction) {
    const searchHistoryStore = db.createObjectStore('temporaryText', {
      keyPath: 'id'
    })
    searchHistoryStore.createIndex('index_updatedTimestamp', 'updatedTimestamp')
    searchHistoryStore.createIndex('index_createdTimestamp', 'createdTimestamp')
  },
  blocked () {
    //
  },
  blocking () {
    //
  },
  terminated () {
    //
  }
})

export default temporaryTextDatabase
