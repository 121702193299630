import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'

const Head: React.FC<{}> = () => {
  return <Switch>
    <Route path="/new" exact>
      <Helmet>
        <title>新規テキスト - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/texts/:id/edit">
      <Helmet>
        <title>編集 - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/projects/:id">
      <Helmet>
        <title>プロジェクト - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/texts">
      <Helmet>
        <title>テキスト一覧 - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/home">
      <Helmet>
        <title>ホーム - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/contact">
      <Helmet>
        <title>お問い合わせ - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/privacy">
      <Helmet>
        <title>プライバシーポリシー - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/tos">
      <Helmet>
        <title>利用規約 - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/me">
      <Helmet>
        <title>アカウント情報 - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/help">
      <Helmet>
        <title>ヘルプ - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/auth/authorization">
      <Helmet>
        <title>アクセスの許可 - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/auth/credentials">
      <Helmet>
        <title>アクセス権限の管理 - TATEditor</title>
      </Helmet>
    </Route>
    <Route path="/">
      <Helmet>
        <title>TATEditor</title>
      </Helmet>
    </Route>
  </Switch>
}

export default Head
