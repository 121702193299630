import React from 'react'
import { ReactComponent as OpenInNewIcon } from 'material-design-icons/action/svg/production/ic_open_in_new_24px.svg'
import { useAppSelector } from '../app/hooks'
import { selectHasLoggedIn } from '../store/userSlice'
import { Link } from 'react-router-dom'

const ItemLabel: React.FC<{ children?: React.ReactNode }> = props => {
  return <div className={'m-8px'} style={{
    textAlign: 'end'
  }}>
    {props.children}
  </div>
}

const FixItem: React.FC<{ children: React.ReactNode }> = props => {
  return <div className={'m-8px text-center'}>
    {props.children}
  </div>
}

const WipItem: React.FC<{ children: React.ReactNode }> = props => {
  return <div className={'m-8px text-center text-lightDisabled dark:text-darkDisabled'}>
    {props.children}
  </div>
}

const About: React.FC<{}> = () => {
  const hasLoggedIn = useAppSelector(selectHasLoggedIn)
  return <div className={'font-mincho flex flex-col items-center text-lightTextSecondary dark:text-darkTextSecondary py-24px md:py-96px'}>
    <h1 className={'mx-24px md:mx-96px text-24px md:text-40px'}>TATEditorとは</h1>
    <h2 className={'mx-24px md:mx-96px text-24px md:text-40px underline my-32px md:my-48px'}>シンプルな執筆ツール</h2>
    <div className={'flex justify-center items-center text-center w-240px h-48px px-16px m-16px bg-accent text-white rounded-8px shadow'}><Link to={'/signin'} className={'text-24px font-bold font-sans'}>{hasLoggedIn ? 'ホームに行く' : '無料ではじめる'}</Link></div>
    <div className={'opacity-60 p-4px'}><a className={'flex items-center'} href="https://apps.apple.com/app/id1477764116">iOSアプリをダウンロード<OpenInNewIcon className={'inline-block w-16px h-16px'}/></a></div>
    <div className={'opacity-60 p-4px'}><a className={'flex items-center'} href="https://play.google.com/store/apps/details?id=net.cc4966.tateditor">Androidアプリをダウンロード<OpenInNewIcon className={'inline-block w-16px h-16px'}/></a></div>
    <div className={'opacity-60 p-4px'}><a className={'flex items-center'} href="https://www.cc4966.net/">デスクトップ版をダウンロード<OpenInNewIcon className={'inline-block w-16px h-16px'}/></a></div>
    <div className={'opacity-60 p-4px'}><a className={'flex items-center'} href="https://marketplace.visualstudio.com/items?itemName=tateditor.app">VS Code拡張をダウンロード<OpenInNewIcon className={'inline-block w-16px h-16px'}/></a></div>
    <h2 className={'mx-24px md:mx-96px text-24px md:text-40px underline my-32px md:my-48px'}>できること</h2>
    <div className={'flex overflow-x-auto max-w-full px-24px'}>
      <div className={'grid grid-cols-aboutFunctions text-12px'}>
        <ItemLabel></ItemLabel>
        <>
          <FixItem>Web</FixItem>
          <FixItem><a className={'link'} href="https://apps.apple.com/app/id1477764116">iOSアプリ</a></FixItem>
          <FixItem><a className={'link'} href="https://play.google.com/store/apps/details?id=net.cc4966.tateditor">Androidアプリ</a></FixItem>
          <FixItem><a className={'link'} href="https://www.cc4966.net/">デスクトップ版</a></FixItem>
          <FixItem><a className={'link'} href="https://marketplace.visualstudio.com/items?itemName=tateditor.app">VS Code拡張</a></FixItem>
        </>
        <ItemLabel>アカウント同期機能</ItemLabel>
        <>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
          <WipItem>開発予定</WipItem>
          <WipItem>あり</WipItem>
        </>
        <ItemLabel>縦書き編集</ItemLabel>
        <>
          <WipItem>未定</WipItem>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
          <WipItem>なし</WipItem>
        </>
        <ItemLabel>横書き編集</ItemLabel>
        <>
          <FixItem>あり</FixItem>
          <WipItem>開発予定</WipItem>
          <WipItem>開発予定</WipItem>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
        </>
        <ItemLabel>テキスト保存履歴</ItemLabel>
        <>
          <FixItem>あり</FixItem>
          <WipItem>開発予定</WipItem>
          <WipItem>開発予定</WipItem>
          <WipItem>未定</WipItem>
          <WipItem>未定</WipItem>
        </>
        <ItemLabel>PDF作成</ItemLabel>
        <>
          <WipItem>未定</WipItem>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
          <WipItem>なし</WipItem>
        </>
        <ItemLabel>ルビの表示</ItemLabel>
        <>
          <WipItem>未定</WipItem>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
          <WipItem>なし</WipItem>
        </>
        <ItemLabel>印刷レイアウトで編集</ItemLabel>
        <>
          <WipItem>未定</WipItem>
          <FixItem>あり</FixItem>
          <WipItem>開発予定</WipItem>
          <FixItem>あり</FixItem>
          <WipItem>なし</WipItem>
        </>
        <ItemLabel>メモ機能</ItemLabel>
        <>
          <WipItem>開発予定</WipItem>
          <FixItem>あり</FixItem>
          <FixItem>あり</FixItem>
          <WipItem>未定</WipItem>
          <WipItem>未定</WipItem>
        </>
        <ItemLabel>動作環境</ItemLabel>
        <>
          <FixItem>最近のブラウザ</FixItem>
          <FixItem>iOS 11.0~</FixItem>
          <FixItem>Android 5.0~</FixItem>
          <FixItem>Windows, macOS, Ubuntu</FixItem>
          <FixItem>1.61~, vscode.dev</FixItem>
        </>
      </div>
    </div>
    {/* <h2>スクリーンショット</h2> */}
    <footer className={'mt-32px text-12px'}>
      <Link to="/tos">利用規約</Link>・<Link to="/privacy">プライバシーポリシー</Link>・<Link to="/contact">お問い合わせ</Link>
    </footer>
  </div>
}

export default About
