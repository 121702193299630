import { DBSchema, openDB } from 'idb'

const databaseName = 'tateditor-search-history-database'
const databaseVersion = 1

export interface IdbSearch {
  word: string
  searchCount: number
  updatedTimestamp: number
  createdTimestamp: number
}

interface DatabaseSchema extends DBSchema {
  'searchHistory': {
    key: string
    value: IdbSearch
    indexes: {
      'index_updatedTimestamp': number
      'index_createdTimestamp': number
      'index_searchCount': number
    }
  }
}

const searchHistoryDatabase = openDB<DatabaseSchema>(databaseName, databaseVersion, {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  upgrade (db, oldVersion, newVersion, transaction) {
    const searchHistoryStore = db.createObjectStore('searchHistory', {
      keyPath: 'word'
    })
    searchHistoryStore.createIndex('index_updatedTimestamp', 'updatedTimestamp')
    searchHistoryStore.createIndex('index_createdTimestamp', 'createdTimestamp')
    searchHistoryStore.createIndex('index_searchCount', 'searchCount')
  },
  blocked () {
    //
  },
  blocking () {
    //
  },
  terminated () {
    //
  }
})

export default searchHistoryDatabase
