import { postContent } from '../api/ApiRequest'
import { NetworkStateReducer, updateTokenIfNeeded } from '../store/NetworkStore'
import { ProjectListItemData, TextData } from './Models'

export async function createProject (
  title: string | null,
  network: NetworkStateReducer
): Promise<ProjectListItemData> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await postContent(title, undefined, undefined, undefined, accessToken)
  return {
    id: result.content.contentId,
    title: result.content.contentTitle ?? null,
    savedTimestamp: result.content.contentUserUpdatedTimestamp, // dummy
    createdTimestamp: result.content.contentUserCreatedTimestamp,
    characterCount: 0,
    lineCount: 0,
    textCount: 0,
    text: null
  }
}

export async function createText (
  text: string,
  title: string | null,
  project: ProjectListItemData | undefined,
  network: NetworkStateReducer
): Promise<TextData> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await postContent(title, project?.id, undefined, text, accessToken)
  if (result.content.textId == null || result.content.textUserUpdatedTimestamp == null || result.content.textUserCreatedTimestamp == null || result.content.textLineCount == null || result.content.textCharacterCount == null) {
    throw new Error('response error')
  }
  return {
    id: result.content.textId,
    savedTimestamp: result.content.textUserUpdatedTimestamp,
    createdTimestamp: result.content.textUserCreatedTimestamp,
    lineCount: result.content.textLineCount,
    characterCount: result.content.textCharacterCount,
    content: {
      id: result.content.contentId,
      title: result.content.contentTitle ?? null
    },
    project: project ? {
      id: project.id,
      title: project.title,
      edge: {
        displayNumber: project.textCount + 1
      }
    } : null,
    text: text,
    revisionNumber: 1
  }
}
