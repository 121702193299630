import { createText } from '../repository/Create'
import { TextData } from '../repository/Models'
import { updateText } from '../repository/Update'
import { clearDraft, clearTemporaryText } from '../storage/Draft'
import { showContextMenu } from '../store/contextMenuSlice'
import { NetworkStateReducer } from '../store/NetworkStore'
import { addNewText, replaceText } from '../store/serverSlice'
import { showToast } from '../store/toastSlice'
import { AppDispatch } from './store'

export const textareaId = 'main_editor'
export const dummyTextareaId = 'dummy_editor'
export const searchInputId = 'search_input'

function getTextarea (): HTMLTextAreaElement | null {
  return document.getElementById(textareaId) as HTMLTextAreaElement | null
}

function getDummyTextarea (): HTMLTextAreaElement | null {
  return document.getElementById(dummyTextareaId) as HTMLTextAreaElement | null
}

export function focusSearchInput () {
  document.getElementById(searchInputId)?.focus()
}

export function focusEditor () {
  getTextarea()?.focus()
}

export function getCurrentEditorValue (): string {
  const textarea = getTextarea()
  return textarea?.value ?? ''
}

export function moveCaret (start: number, end: number, direction?: 'forward' | 'backward' | 'none' | undefined) {
  const textarea = getTextarea()
  if (!textarea) {
    return
  }
  textarea.focus()
  textarea.setSelectionRange(start, end, direction)
  const dummyTextArea = getDummyTextarea()
  if (!dummyTextArea) {
    return
  }
  dummyTextArea.value = textarea.value.substr(0, Math.max(start, end))
  // padding-top = 120
  const scrollOffset = dummyTextArea.scrollHeight
  textarea.scrollTo(0, scrollOffset)
}

let currentTextId: number | null = null

export function getEditingTextId (): number | null {
  return currentTextId
}

export function initializeEditingTextId (textId: number | null) {
  currentTextId = textId
}

export function restoreBackup (text: string, textId: number | null) {
  if (currentTextId !== textId) {
    return
  }
  const textarea = getTextarea()
  if (!textarea) {
    return
  }
  textarea.focus()
  if (document.execCommand('selectAll') && document.execCommand('insertText', false, text)) {
    return
  }
  // for Firefox
  textarea.value = text
}

export function saveText (text: TextData, network: NetworkStateReducer, dispatch: AppDispatch) {
  const textarea = getTextarea()
  if (!textarea) {
    return
  }
  if (text.text === textarea.value) {
    dispatch(showToast({ type: 'info', message: '変更がありません' }))
    return
  }
  if (textarea.dataset.save === 'saving') {
    return
  }
  textarea.dataset.save = 'saving'
  updateText(text, textarea.value, network)
    .then(res => {
      clearTemporaryText(text.id)
      dispatch(replaceText(res))
      dispatch(showToast({ type: 'success', message: '保存しました' }))
    })
    .catch(() => {
      dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
    })
    .finally(() => {
      textarea.dataset.save = undefined
    })
}

export function createNewText (network: NetworkStateReducer, dispatch: AppDispatch, onSuccess: (text: TextData) => void) {
  const textarea = getTextarea()
  if (!textarea) {
    return
  }
  if (textarea.dataset.save === 'saving') {
    return
  }
  textarea.dataset.save = 'saving'
  createText(textarea.value, null, undefined, network)
    .then(res => {
      clearDraft()
      dispatch(addNewText(res))
      dispatch(showToast({ type: 'success', message: '保存しました' }))
      textarea.dataset.id = res.id.toString()
      onSuccess(res)
    })
    .catch(() => {
      dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
    })
    .finally(() => {
      textarea.dataset.save = undefined
    })
}

export function share (text: TextData | undefined) {
  const textarea = getTextarea()
  if (!textarea) {
    return
  }
  navigator.share({
    title: text?.content.title ?? undefined,
    text: textarea.value,
    url: undefined
  })
}

export function download (text: TextData | undefined) {
  const textarea = getTextarea()
  if (!textarea) {
    return
  }
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([textarea.value], {
    type: 'text/plain'
  }))
  link.download = `${text?.content.title ?? '無題'}.txt`
  link.dispatchEvent(new MouseEvent('click'))
}

export function downloadText (dispatch: AppDispatch, e: { clientX: number, clientY: number }, text: TextData | undefined) {
  if ('share' in navigator) {
    dispatch(showContextMenu({
      position: {
        clientX: e.clientX,
        clientY: e.clientY
      },
      items: [
        {
          label: 'シェア',
          onClick () {
            share(text)
          }
        },
        {
          label: 'ダウンロード',
          onClick () {
            download(text)
          }
        }
      ]
    }))
  } else {
    download(text)
  }
}

export function searchWord (word: string) {
  const textarea = getTextarea()
  if (!textarea) {
    return
  }
  const offset = Math.max(textarea.selectionStart, textarea.selectionEnd)
  let start = textarea.value.indexOf(word, offset)
  if (start < 0) {
    start = textarea.value.indexOf(word, 0)
    if (start < 0) {
      return
    }
  }
  const end = start + word.length
  moveCaret(start, end)
}

export function undoEditor (dispatch: AppDispatch) {
  const textarea = getTextarea()
  if (/firefox/i.test(navigator.userAgent)) {
    dispatch(showToast({
      type: 'warning',
      message: 'Command+Z か Control+Z をお使いください'
    }))
    return
  }
  textarea?.focus()
  if (!document.execCommand('undo')) {
    dispatch(showToast({
      type: 'info',
      message: '変更がありません'
    }))
  }
}

export function redoEditor (dispatch: AppDispatch) {
  const textarea = getTextarea()
  if (/firefox/i.test(navigator.userAgent)) {
    dispatch(showToast({
      type: 'warning',
      message: 'Command+Shift+Z か Control+Shift+Z をお使いください'
    }))
    return
  }
  textarea?.focus()
  if (!document.execCommand('redo')) {
    dispatch(showToast({
      type: 'info',
      message: '変更がありません'
    }))
  }
}

export function cutEditor (dispatch: AppDispatch) {
  try {
    const textarea = getTextarea()
    navigator.clipboard.readText()
      .then(text => {
        textarea?.focus()
        document.execCommand('cut', false, text)
      })
      .catch(err => {
        console.error(err)
      })
  } catch {
    dispatch(showToast({
      type: 'warning',
      message: 'Command+X か Control+X をお使いください'
    }))
  }
}

export function copyEditor (dispatch: AppDispatch) {
  const textarea = getTextarea()
  if (!textarea) {
    return
  }
  const text = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd)
  navigator.clipboard.writeText(text)
    .then(() => {
      dispatch(showToast({
        type: 'success',
        message: 'コピーしました'
      }))
    })
    .catch(() => {
      dispatch(showToast({
        type: 'warning',
        message: 'Command+C か Control+C をお使いください'
      }))
    })
}

export function pasteEditor (dispatch: AppDispatch) {
  try {
    const textarea = getTextarea()
    navigator.clipboard.readText()
      .then(text => {
        textarea?.focus()
        document.execCommand('insertText', false, text)
      })
      .catch(err => {
        console.error(err)
      })
  } catch {
    dispatch(showToast({
      type: 'warning',
      message: 'Command+V か Control+V をお使いください'
    }))
  }
}
