import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link, Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import PageLoad from '../components/loading/PageLoad'
import { ReactComponent as ChevronRightIcon } from 'material-design-icons/navigation/svg/production/ic_chevron_right_24px.svg'
import { ReactComponent as ExpandMoreIcon } from 'material-design-icons/navigation/svg/production/ic_expand_more_24px.svg'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { addHelpHistory, Article, selectHelp, selectHelpHistory, setHelp } from '../store/helpSlice'
import SearchInput from '../components/editor/SearchInput'
import { showModal } from '../store/modalSlice'
import HelpModal from '../components/modals/HelpModal'
import ReactMarkdown from 'react-markdown'
import { IdbHelp } from '../database/helpHistory/HelpHistoryDatabase'
import { getAllIdbHelp } from '../database/helpHistory/HelpHistoryDao'
import styled from 'styled-components'

const HelpCategoryHeader: React.FC<{
  slug: string
}> = props => {
  const help = useAppSelector(selectHelp)
  const article = help?.articles.find(article => article.id === props.slug)
  if (!article || article.categories.length === 0) {
    return <h2 className={'mb-16px text-24px md:text-40px flex flex-wrap items-center'}><Link to="/help">ヘルプ</Link></h2>
  }
  return <h2 className={'mb-16px text-24px md:text-40px flex flex-wrap items-center'}><Link to="/help">ヘルプ</Link><ChevronRightIcon className={'shrink-0 w-40px h-40px p-8px'}/>{article.categories[0]}</h2>
}

const HelpHeader: React.FC<{}> = () => {
  const { slug } = useParams<{ slug: string }>()
  const { search } = useLocation()
  const word = new URLSearchParams(search).get('q')
  if (word) {
    return <h2 className={'mb-16px text-24px md:text-40px flex flex-wrap items-center'}><Link to="/help">ヘルプ</Link><ChevronRightIcon className={'shrink-0 w-40px h-40px p-8px'}/>キーワード検索</h2>
  } else if (slug) {
    return <HelpCategoryHeader slug={slug}/>
  } else {
    return <h2 className={'mb-16px text-24px md:text-40px flex flex-wrap items-center'}>ヘルプ</h2>
  }
}

const KeywordList: React.FC<{
  label?: string
  keywords: string[]
  onSelect?: (word: string) => void
}> = props => {
  const history = useHistory()
  return <ul className={'flex flex-wrap'}>
    {props.label ? <li className='mr-12px'>{props.label}</li> : undefined}
    {props.keywords.map(keyword => {
      return <li className={'px-4px inline-block border rounded-4px mr-12px last:mr-0 mb-8px cursor-pointer border-lightBorder dark:border-darkBorder'} key={keyword} onClick={() => {
        const search = new URLSearchParams()
        search.set('q', keyword)
        const pathname = `/help?${search.toString()}`
        if (pathname !== history.location.pathname) {
          history.push(pathname)
        }
        if (props.onSelect) {
          props.onSelect(keyword)
        }
      }}>{keyword}</li>
    })}
  </ul>
}

const HelpSearchBar: React.FC<{
  keywords: string[]
}> = props => {
  const searchInput = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const { search } = useLocation()
  const word = new URLSearchParams(search).get('q')
  return <form onSubmit={e => {
    e.preventDefault()
    const newWord = searchInput.current?.value
    if (newWord) {
      const search = new URLSearchParams()
      search.set('q', newWord)
      const pathname = `/help?${search.toString()}`
      if (word !== newWord) {
        history.push(pathname)
      }
    } else {
      const pathname = '/help'
      if (word !== newWord) {
        history.push(pathname)
      }
    }
  }}>
    <SearchInput defaultValue={word ?? undefined} ref={searchInput} type="text" className={'pl-16px md:pl-48px pr-96px w-full h-48px  focus:bg-lightSurfaceFocus dark:focus:bg-darkSurfaceFocus rounded-8px shadow dark:border dark:border-darkBorder'}/>
    <div className={'md:ml-48px mt-8px flex items-center'}>
      <KeywordList label='キーワード:' keywords={props.keywords} onSelect={word => {
        if (searchInput.current) {
          searchInput.current.value = word
        }
      }}/>
    </div>
  </form>
}

const StyledMarkdown = styled(ReactMarkdown)`
& li {
  &:before {
    content: '・';
  }
}
& p {
  margin-bottom: 16px;
}
& ul {
  margin-bottom: 16px;
}
`

const HelpArticle: React.FC<{}> = () => {
  const help = useAppSelector(selectHelp)
  const { slug } = useParams<{ slug: string }>()
  const dispatch = useAppDispatch()
  const article = help?.articles.find(article => article.id === slug)
  useEffect(() => {
    if (article) {
      dispatch(addHelpHistory(article))
    }
  }, [article?.id])
  if (!help) {
    return <>読み込み中</>
  }
  if (!article) {
    return <Switch>
      <Redirect to="/help"/>
    </Switch>
  }
  return <section className='md:mx-48px md:my-48px'>
    <KeywordList keywords={article.platforms}/>
    <h1 className={'text-24px mb-24px'}>{article.title}</h1>
    <StyledMarkdown
      components={{
        a (props) {
          if (!props.href || props.href.includes('://')) {
            return <a {...props} className={'underline'} target={'_blank'}/>
          }
          return <Link to={props.href} {...props} className={'underline'}></Link>
        }
      }}
      className={'mb-24px'}
    >{article.content}</StyledMarkdown>
    <KeywordList label='キーワード:' keywords={article.keywords}/>
  </section>
}

const HelpArticleListItem: React.FC<{
  article: Article
}> = props => {
  return <li key={props.article.id} className={'w-full'}><Link to={`/help/articles/${props.article.id}`} className={'flex pl-12px py-8px justify-between hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}>{props.article.title}<ChevronRightIcon className='shrink-0'/></Link></li>
}

const HelpArticleList: React.FC<{
  label: string
  articles: Article[]
  limit?: number
}> = props => {
  const [showAll, setShowAll] = useState(!props.limit || props.articles.length <= props.limit)
  return <>
    <h3 className={'pt-16px pb-8px text-24px'}>{props.label} ({props.articles.length})</h3>
    <ul>{props.articles.slice(0, showAll ? undefined : props.limit).map(article => <HelpArticleListItem key={article.id} article={article}/>)}</ul>
    <div className={showAll ? 'hidden' : 'flex justify-center'}><button className='btn-normal m-8px' onClick={() => setShowAll(true)}>すべて表示</button></div>
  </>
}

const HelpArticleToggleList: React.FC<{
  label: string
  articles: Article[]
}> = props => {
  const [isOpen, setOpen] = useState(false)
  return <li className={'w-full pl-12px'}>
    <h4 className={'flex py-8px hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'} onClick={() => {
      setOpen(!isOpen)
    }}>{isOpen ? <ExpandMoreIcon style={{ width: '25px', height: '25px' }}/> : <ChevronRightIcon className='shrink-0' style={{ width: '25px', height: '25px' }}/>}{props.label} ({props.articles.length})</h4>
    <ul className={isOpen ? 'ml-12px border-l border-lightBorder dark:border-darkBorder' : 'hidden'}>{props.articles.map(article => <HelpArticleListItem key={article.id} article={article}/>)}</ul>
  </li>
}

const HelpArticleGroupList: React.FC<{
  label: string
  groups: {
    label: string
    articles: Article[]
  }[]
}> = props => {
  return <>
    <h3 className={'pt-16px pb-8px text-24px'}>{props.label}</h3>
    <ul>{props.groups.map(group => <HelpArticleToggleList key={group.label} {...group}/>)}</ul>
  </>
}

const HelpIndex: React.FC<{}> = () => {
  const help = useAppSelector(selectHelp)
  if (!help) {
    return null
  }
  const categories = help.articles.flatMap(article => article.categories).filter((elem, index, self) => self.indexOf(elem) === index)
  const platforms = help.articles.flatMap(article => article.platforms).filter((elem, index, self) => self.indexOf(elem) === index)
  const revisedTimestamps = help.articles.map(article => article.revised)
  const maxRevisedTimedstamp = Math.max(...revisedTimestamps)
  return <>
    <HelpArticleList label={'よくある質問'} limit={3} articles={help.articles.filter(article => article.faq === 'Yes')}/>
    <HelpArticleList label={'最近の質問とその回答'} limit={3} articles={help.articles.filter(article => article.revised + 7 * 24 * 60 * 60 * 1000 > maxRevisedTimedstamp)}/>
    <HelpArticleGroupList label={'カテゴリーから探す'} groups={categories.map(category => {
      return {
        label: category,
        articles: help.articles.filter(articles => articles.categories.includes(category))
      }
    })}/>
    <HelpArticleGroupList label={'プラットフォームから調べる'} groups={platforms.map(platform => {
      return {
        label: platform,
        articles: help.articles.filter(articles => articles.platforms.includes(platform))
      }
    })}/>
  </>
}

const HelpFeedback: React.FC<{}> = () => {
  const help = useAppSelector(selectHelp)
  const { slug } = useParams<{ slug: string }>()
  const dispatch = useAppDispatch()
  const article = help?.articles.find(article => article.id === slug)
  return <>
    <h3 className={'pt-16px pb-8px text-24px'}>解決しない場合</h3>
    {article ? <div className={'my-8px mx-16px p-16px rounded-8px flex justify-between items-center border border-lightBorder dark:border-darkBorder hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'} onClick={() => {
      dispatch(showModal({ component () { return <HelpModal subtype='notFound'/> } }))
    }}>
      この記事に不足している内容を報告する<ChevronRightIcon className='shrink-0'/>
    </div> : undefined}
    {article?.request === 'Yes' ? <div className={'my-8px mx-16px p-16px rounded-8px flex justify-between items-center border border-lightBorder dark:border-darkBorder hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'} onClick={() => {
      dispatch(showModal({ component () { return <HelpModal subtype='notImplemented'/> } }))
    }}>
      未実装の機能について要望を送る<ChevronRightIcon className='shrink-0'/>
    </div> : undefined}
    <div className={'my-8px mx-16px p-16px rounded-8px flex justify-between items-center border border-lightBorder dark:border-darkBorder hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'} onClick={() => {
      dispatch(showModal({ component () { return <HelpModal subtype='notEnough'/> } }))
    }}>
      新規に必要な記事をフィードバックする<ChevronRightIcon className='shrink-0'/>
    </div>
  </>
}

const HelpHistory: React.FC<{}> = () => {
  const help = useAppSelector(selectHelp)
  const helpHistory = useAppSelector(selectHelpHistory)
  const { slug } = useParams<{ slug: string }>()
  if (!help) {
    return null
  }
  const articleIds = helpHistory.map(item => item.id).filter(id => id !== slug)
  const articles = articleIds.flatMap(id => {
    const article = help.articles.find(article => article.id === id)
    return article ? [article] : []
  })
  return <HelpArticleList label={'最近表示した記事'} limit={3} articles={articles}/>
}

const HelpTop: React.FC<{}> = () => {
  const { search } = useLocation()
  const word = new URLSearchParams(search).get('q')
  const help = useAppSelector(selectHelp)
  if (!word || !help) {
    return <HelpIndex/>
  }
  const articles = help.articles.filter(articles => {
    // WIP: improve
    return articles.categories.includes(word) || articles.platforms.includes(word) || articles.keywords.includes(word)
  })
  return <>
    <HelpArticleList label={`"${word}"の検索結果`} articles={articles}/>
    <HelpFeedback/>
  </>
}

const Help: React.FC<{}> = () => {
  const help = useAppSelector(selectHelp)
  const dispatch = useAppDispatch()
  if (!help) {
    return <PageLoad<{
      articles: Article[]
      history: IdbHelp[]
    }>
      fetch={async () => {
        const response = await axios.create().get<{ articles: Article[] }>('/help/articles.json')
        const history = await getAllIdbHelp()
        return {
          articles: response.data.articles,
          history: history
        }
      }}
      dispatch={response => dispatch(setHelp(response))}
    />
  }
  const keywords = help.articles.flatMap(article => article.keywords).filter((elem, index, self) => self.indexOf(elem) === index)
  return <div className={'font-mincho p-24px md:p-96px leading-document'}>
    <Switch>
      <Route path={'/help/articles/:slug'}>
        <HelpHeader/>
        <HelpArticle/>
        <HelpHistory/>
        <HelpFeedback/>
      </Route>
      <Route path={'/help'}>
        <HelpHeader/>
        <HelpSearchBar keywords={keywords}/>
        <HelpTop/>
      </Route>
      <Redirect to="/"/>
    </Switch>
  </div>
}

export default Help
