import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import { getAuth, signOut, User } from 'firebase/auth'

// Define a type for the slice state
interface UserState {
  hasLoggedIn?: boolean
  accessToken?: {
    token: string
    expireTimestamp: number
  }
}

// Define the initial state using that type
const initialState: UserState = {
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    clearUserState: (state) => {
      state.hasLoggedIn = undefined
    },
    applyUserState: (state, action: PayloadAction<User | null>) => {
      state.hasLoggedIn = action.payload !== null
    },
    logout: (state) => {
      if (confirm('ログアウトしますか？')) {
        signOut(getAuth())
        state.hasLoggedIn = false
      }
    }
  }
})

export const { clearUserState, applyUserState, logout } = userSlice.actions

export const selectIsLoadingUser = (state: RootState) => state.userSlice.hasLoggedIn === undefined
export const selectHasLoggedIn = (state: RootState): boolean => state.userSlice.hasLoggedIn !== undefined && state.userSlice.hasLoggedIn

export default userSlice.reducer
