import React from 'react'
import TextCard from './cards/TextCard'
import ProjectCard from './cards/ProjectCard'
import SectionCard from './cards/SectionCard'
import { ProjectListItemData, SectionListItemData, TextListItemData, TextSectionListItemData } from '../../repository/Models'
import EmptyCard from './cards/EmptyCard'
import CardContainer from './cards/CardContainer'

interface ProjectCardListContainerProps {
  oneline?: boolean
  projects: ProjectListItemData[]
}

interface SectionCardListContainerProps {
  oneline?: boolean
  project: ProjectListItemData
  sections: SectionListItemData[]
}

interface TextCardListContainerProps {
  oneline?: boolean
  texts: TextListItemData[]
}

export type CardListContainerProps = ProjectCardListContainerProps | SectionCardListContainerProps | TextCardListContainerProps

const CardListContainer: React.FC<CardListContainerProps> = props => {
  if ('projects' in props) {
    if (props.projects.length === 0) {
      return <div className={'-mx-8px pb-16px flex flex-wrap justify-start'}>
        <CardContainer><EmptyCard type={'project'} parent={null}/></CardContainer>
      </div>
    }
  } else if ('sections' in props) {
    if (props.sections.length === 0) {
      return <div className={'-mx-8px pb-16px flex flex-wrap justify-start'}>
        <CardContainer><EmptyCard type={'text'} parent={props.project}/></CardContainer>
      </div>
    }
  } else {
    if (props.texts.length === 0) {
      return <div className={'-mx-8px pb-16px flex flex-wrap justify-start'}>
        <CardContainer><EmptyCard type={'text'} parent={null}/></CardContainer>
      </div>
    }
  }
  return (
    <div className={'-mx-8px pb-16px flex flex-wrap justify-start'}>
      {('projects' in props) ? props.projects.map(project => {
        return <CardContainer className={props.oneline ? 'oneline' : undefined} key={project.id}><ProjectCard project={project}/></CardContainer>
      }) : ('sections' in props) ? props.sections.flatMap<TextSectionListItemData>(section => section.text ? [section] : []).map((section, i) => {
        return <CardContainer className={props.oneline ? 'oneline' : undefined} key={section.id}><SectionCard index={i} project={props.project} section={section}/></CardContainer>
      }) : props.texts.map(text => {
        return <CardContainer className={props.oneline ? 'oneline' : undefined} key={text.id}><TextCard text={text}/></CardContainer>
      })}
    </div>
  )
}

export default CardListContainer
