import React from 'react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'

export interface ModalProps {
  component: React.FC<{}>
}

interface ModalState {
  props?: ModalProps
}

// Define the initial state using that type
const initialState: ModalState = {
}

export const ModalSlice = createSlice({
  name: 'modalSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<ModalProps>) => {
      state.props = action.payload
    },
    dismissModal: (state) => {
      state.props = undefined
    }
  }
})

export const { showModal, dismissModal } = ModalSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectModalProps = (state: RootState) => state.modalSlice.props

export default ModalSlice.reducer
