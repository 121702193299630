import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import { getSetting, saveSetting } from '../storage/Settings'

interface EditorState {
  settings: {
    minchoEnabled: boolean
    nowrapEnabled: boolean
    fontSize: number
  }
  editUuid: string
}

function parseFontSize (value: string): number {
  const fontSizeString = value.match(/[0-9]+/) ?? ['16']
  const fontSizeNumber = Number.parseInt(fontSizeString[0])
  return isNaN(fontSizeNumber) ? 16 : fontSizeNumber
}

function getFontSize (): number {
  const value = getSetting('editor-font-size') ?? '16px'
  return parseFontSize(value)
}

function saveFontSize (value: string): number {
  const fontSize = parseFontSize(value)
  saveSetting('editor-font-size', `${fontSize}px`)
  return fontSize
}

// Define the initial state using that type
const initialState: EditorState = {
  settings: {
    minchoEnabled: !(getSetting('editor-font-typeface') !== 'mincho'),
    nowrapEnabled: getSetting('editor-white-space') === 'pre',
    fontSize: getFontSize()
  },
  editUuid: ''
}

export const EditorSlice = createSlice({
  name: 'editorSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleMincho: (state) => {
      state.settings.minchoEnabled = !state.settings.minchoEnabled
      saveSetting('editor-font-typeface', state.settings.minchoEnabled ? 'mincho' : 'gothic')
    },
    toggleNowrap: (state) => {
      state.settings.nowrapEnabled = !state.settings.nowrapEnabled
      saveSetting('editor-white-space', state.settings.nowrapEnabled ? 'pre' : 'normal')
    },
    setFontSize: (state, action: PayloadAction<string>) => {
      state.settings.fontSize = saveFontSize(action.payload)
    },
    onEdit: (state) => {
      state.editUuid = `${new Date().getTime()}.${Math.random()}`
    }
  }
})

export const { toggleMincho, toggleNowrap, setFontSize, onEdit } = EditorSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectEditorSettings = (state: RootState) => state.editorSlice.settings

export const selectEditUuid = (state: RootState) => state.editorSlice.editUuid

export default EditorSlice.reducer
