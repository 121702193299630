import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { clearToast, dismissToast, selectToasts, Toast } from '../../store/toastSlice'
import { ReactComponent as ErrorIcon } from 'material-design-icons/alert/svg/production/ic_error_24px.svg'
import { ReactComponent as SuccessIcon } from 'material-design-icons/action/svg/production/ic_check_circle_24px.svg'
import { ReactComponent as WarningIcon } from 'material-design-icons/alert/svg/production/ic_warning_24px.svg'
import { ReactComponent as InfoIcon } from 'material-design-icons/action/svg/production/ic_info_24px.svg'

const Never: React.FC<{
  never: never
}> = props => {
  return props.never
}

const ToastItem: React.FC<{
  toast: Toast
}> = props => {
  if (props.toast.type === 'error') {
    return <li className={`bg-errorRed text-white shadow min-w-widthToast max-w-full mb-8px px-16px py-8px rounded-4px flex items-center justify-center transition-all duration-300 ${props.toast.clearedTimestamp ? 'opacity-0' : 'opacity-100'}`}>
      <ErrorIcon className={'fill-current mr-4px'}/><span>{props.toast.message}</span>
    </li>
  }
  if (props.toast.type === 'success') {
    return <li className={`bg-successGreen text-white shadow min-w-widthToast max-w-full mb-8px px-16px py-8px rounded-4px flex items-center justify-center transition-all duration-300 ${props.toast.clearedTimestamp ? 'opacity-0' : 'opacity-100'}`}>
      <SuccessIcon className={'fill-current mr-4px'}/><span>{props.toast.message}</span>
    </li>
  }
  if (props.toast.type === 'warning') {
    return <li className={`bg-warningYellow text-white shadow min-w-widthToast max-w-full mb-8px px-16px py-8px rounded-4px flex items-center justify-center transition-all duration-300 ${props.toast.clearedTimestamp ? 'opacity-0' : 'opacity-100'}`}>
      <WarningIcon className={'fill-current mr-4px'}/><span>{props.toast.message}</span>
    </li>
  }
  if (props.toast.type === 'info') {
    return <li className={`bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary shadow min-w-widthToast max-w-full mb-8px px-16px py-8px rounded-4px flex items-center justify-center transition-all duration-300 ${props.toast.clearedTimestamp ? 'opacity-0' : 'opacity-100'}`}>
      <InfoIcon className={'fill-current mr-4px'}/><span>{props.toast.message}</span>
    </li>
  }
  return <Never never={props.toast.type}/>
}

const ToastStack: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const toasts = useAppSelector(selectToasts)
  useEffect(() => {
    toasts
      .filter(toast => !toast.clearedTimestamp)
      .forEach(toast => {
        setTimeout(() => {
          dispatch(dismissToast(toast.uuid))
        }, 3000)
      })
    const clearToastTimeout = setTimeout(() => {
      dispatch(clearToast())
    }, 2000)
    return () => {
      clearTimeout(clearToastTimeout)
    }
  }, [toasts])
  return <ul className={'fixed pointer-events-none flex flex-col justify-end items-center bottom-0 left-0 w-full'}>
    {toasts.map(toast => {
      return <ToastItem key={toast.uuid} toast={toast}/>
    })}
  </ul>
}

export default ToastStack
