import React, { useContext } from 'react'
import { characterCountOfSection, titleOfSection, ProjectListItemData, SectionListItemData, displayNumberOfSection, updateTimeOfSection } from '../../../repository/Models'
import { ReactComponent as MoreVertIcon } from 'material-design-icons/navigation/svg/production/ic_more_vert_24px.svg'
import Sortable from '../Sortable'
import { useAppDispatch } from '../../../app/hooks'
import { NetworkStoreContext } from '../../../store/NetworkStore'
import { showSectionContextMenu } from '../../../app/server'

export interface SectionLineItemProps {
  index: number
  section: SectionListItemData
  project: ProjectListItemData
}

const SectionLineItem: React.FC<SectionLineItemProps> = props => {
  const dispatch = useAppDispatch()
  const network = useContext(NetworkStoreContext)
  if (props.section.text) {
    const path = `/texts/${props.section.text.id}/edit`
    return <Sortable type={'list'} item={{ index: props.index, section: props.section, project: props.project }} path={path}>
      <div className={'flex items-center h-full'}>
        <div className={'ml-16px overflow-x-auto overflow-y-hidden whitespace-nowrap flex-grow'}>
          <h4>{titleOfSection(props.section)}</h4>
        </div>
        <div className={'ml-16px text-right overflow-x-auto overflow-y-hidden whitespace-nowrap shrink-0'}>{characterCountOfSection(props.section)}</div>
        <div className={'w-48px text-right overflow-x-auto overflow-y-hidden whitespace-nowrap shrink-0'}>{displayNumberOfSection(props.section)}</div>
        <div className={'ml-16px w-216px text-right overflow-x-auto overflow-y-hidden whitespace-nowrap hidden sm:block shrink'}>{updateTimeOfSection(props.section)}</div>
        <MoreVertIcon className={'p-8px w-40px h-40px m-4px rounded-full shrink-0 hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={e => {
          showSectionContextMenu(e, dispatch, network, props.section, props.project)
          e.preventDefault()
        }} />
      </div>
    </Sortable>
  } else {
    return <Sortable type={'list'} item={{ index: props.index, section: props.section, project: props.project }}>
      <div className={'flex items-center h-full'}>
        <div className={'ml-16px overflow-x-auto overflow-y-hidden whitespace-nowrap flex-grow'}>
          <h4>{titleOfSection(props.section)}</h4>
        </div>
        <MoreVertIcon className={'p-8px w-40px h-40px m-4px rounded-full shrink-0 hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={e => {
          showSectionContextMenu(e, dispatch, network, props.section, props.project)
          e.preventDefault()
        }} />
      </div>
    </Sortable>
  }
}

export default SectionLineItem
