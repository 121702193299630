import React, { useContext } from 'react'
import { ReactComponent as ArrowDropDownIcon } from 'material-design-icons/navigation/svg/production/ic_arrow_drop_down_24px.svg'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { AppDispatch } from '../../app/store'
import { copyEditor, createNewText, cutEditor, download, focusEditor, focusSearchInput, pasteEditor, redoEditor, saveText, undoEditor } from '../../app/editor'
import { selectEditorSettings, setFontSize, toggleMincho, toggleNowrap } from '../../store/editorSlice'
import { dismissModal, showModal } from '../../store/modalSlice'
import SelectModal from '../modals/SelectModal'
import { TextData } from '../../repository/Models'
import { NetworkStoreContext } from '../../store/NetworkStore'
import { useHistory } from 'react-router-dom'

const StyledDropDown = styled.input`
& + label {
  -webkit-tap-highlight-color: transparent;
}
/* 項目の表示 */
&:checked + label > ul > li:hover > section {
  display: block;
}
/* 項目外に移動して閉じないため */
&:checked + label > ul > li:hover div {
  display: block;
}
/* 項目外をタップして閉じるため */
&:checked + label > div {
  display: block;
}
`

const DropDownMenuItem: React.FC<{
  label: string
  items: {
    label: string
    onClick: () => void
  }[]
}> = props => {
  return <li className={'select-none hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}>
    <span
      className={'flex items-center pl-8px pr-4px h-16px'}
      onClick={e => e.preventDefault()}
      onPointerUp={e => {
        const input = document.getElementById('editor-dropdown-menu') as HTMLInputElement
        input.checked = e.pointerType !== 'mouse' || !input.checked
      }}
    >
      {props.label}<ArrowDropDownIcon className={'w-16px h-16px'}/>
    </span>
    <div className={'fixed hidden w-full h-full left-0 top-80px'}/>
    <section className={'absolute w-120px hidden top-16px'}>
      <ul className={'absolute min-w-full bg-lightSurface dark:bg-darkSurface shadow py-4px'}>
        {props.items.map((item, i) => {
          return <li key={i} className={'px-8px py-4px w-full h-full text-left hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'} onClick={() => {
            item.onClick()
          }}>{item.label}</li>
        })}
      </ul>
    </section>
  </li>
}

function useFileMenuItems (text: TextData | undefined) {
  const dispatch = useAppDispatch()
  const networkStore = useContext(NetworkStoreContext)
  const history = useHistory()
  return [
    {
      label: '検索',
      onClick () {
        focusSearchInput()
      }
    },
    {
      label: '保存',
      onClick () {
        if (text) {
          saveText(text, networkStore, dispatch)
        } else {
          createNewText(networkStore, dispatch, text => {
            history.replace(`/texts/${text.id}/edit`)
          })
        }
      }
    },
    {
      label: 'ダウンロード',
      onClick () {
        download(text)
      }
    }
  ]
}

function createEditMenuItems (dispatch: AppDispatch) {
  return [{
    label: '元に戻す',
    onClick () {
      undoEditor(dispatch)
      focusEditor()
    }
  }, {
    label: 'やり直す',
    onClick () {
      redoEditor(dispatch)
      focusEditor()
    }
  }, {
    label: '切り取り',
    onClick () {
      cutEditor(dispatch)
      focusEditor()
    }
  }, {
    label: 'コピー',
    onClick () {
      copyEditor(dispatch)
      focusEditor()
    }
  }, {
    label: '貼り付け',
    onClick () {
      pasteEditor(dispatch)
      focusEditor()
    }
  }]
}

function useViewMenuItems () {
  const settings = useAppSelector(selectEditorSettings)
  const dispatch = useAppDispatch()
  return [{
    label: settings.nowrapEnabled ? '折り返す' : '折り返さない',
    onClick () {
      dispatch(toggleNowrap())
    }
  }, {
    label: settings.minchoEnabled ? 'ゴシック体を使う' : '明朝体を使う',
    onClick () {
      dispatch(toggleMincho())
    }
  }, {
    label: '文字サイズ',
    onClick () {
      dispatch(showModal({
        component () {
          return <SelectModal
            message={'文字サイズを変更'}
            label={'文字サイズ'}
            defaultValue={`${settings.fontSize}px`}
            optionList={['6px', '7px', '8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px', '40px', '44px', '48px']}
            positive={{
              label: '変更',
              onSubmit (value: string) {
                dispatch(setFontSize(value))
                dispatch(dismissModal())
              }
            }}
            negative={{
              label: 'キャンセル',
              onClick () {
                dispatch(dismissModal())
              }
            }}
          />
        }
      }))
    }
  }]
}

const DropDownMenu: React.FC<{
  text?: TextData
}> = props => {
  const dispatch = useAppDispatch()
  const viewMenu = useViewMenuItems()
  const filemenu = useFileMenuItems(props.text)
  return <div className={'flex'}>
    <StyledDropDown id="editor-dropdown-menu" type="checkbox" className={'hidden'}/>
    <label htmlFor="editor-dropdown-menu">
      <div className={'fixed hidden w-full h-full left-0 top-80px'}/>
      <ul className={'flex text-12px'}>
        <DropDownMenuItem label={'ファイル'} items={filemenu}/>
        <DropDownMenuItem label={'編集'} items={createEditMenuItems(dispatch)}/>
        <DropDownMenuItem label={'表示'} items={viewMenu}/>
      </ul>
    </label>
  </div>
}

export default DropDownMenu
