import React, { useContext } from 'react'
import { ReactComponent as MoreVertIcon } from 'material-design-icons/navigation/svg/production/ic_more_vert_24px.svg'
import { characterCountOfProject, textCountOfProject, ProjectListItemData, titleOfProject, updateTimeOfProject } from '../../../repository/Models'
import Sortable from '../Sortable'
import { showProjectContextMenu } from '../../../app/server'
import { useAppDispatch } from '../../../app/hooks'
import { NetworkStoreContext } from '../../../store/NetworkStore'

export interface ProjectCardProps {
  project: ProjectListItemData
}

const ProjectCard: React.FC<ProjectCardProps> = props => {
  const dispatch = useAppDispatch()
  const network = useContext(NetworkStoreContext)
  const path = props.project.text ? `/texts/${props.project.text.id}/edit` : `/projects/${props.project.id}`
  return <Sortable type={'card'} item={props.project} path={path}>
    <div className={'flex-grow py-8px pl-16px pr-4px flex items-center'}>
      <span className={'flex-grow'}>{textCountOfProject(props.project)}</span>
      <MoreVertIcon className={'shrink-0 p-8px w-40px h-40px rounded-full hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={e => {
        showProjectContextMenu(e, dispatch, network, props.project)
        e.preventDefault()
      }} />
    </div>
    <div className={'px-16px pb-12px grow-0 text-right'}>{characterCountOfProject(props.project)}</div>
    <div className={'px-16px pb-16px grow-0 text-right'}>{updateTimeOfProject(props.project)}</div>
    <div className={'p-16px grow-0 border-t border-lightBorder dark:border-darkBorder'}>{titleOfProject(props.project)}</div>
  </Sortable>
}

export default ProjectCard
