import React from 'react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'

export interface ContextMenuProps {
  position: {
    clientX: number
    clientY: number
  }
  items: ({
    label: string
    onClick: React.MouseEventHandler<HTMLLIElement>
  } | {
    label: string
    path: string
  })[]
}

interface ContextMenuState {
  props?: ContextMenuProps
}

// Define the initial state using that type
const initialState: ContextMenuState = {
}

export const ContextMenuSlice = createSlice({
  name: 'contextMenuSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showContextMenu: (state, action: PayloadAction<ContextMenuProps>) => {
      state.props = action.payload
    },
    dismissContextMenu: (state) => {
      state.props = undefined
    }
  }
})

export const { showContextMenu, dismissContextMenu } = ContextMenuSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectContextMenuProps = (state: RootState) => state.contextMenuSlice.props

export default ContextMenuSlice.reducer
