import React, { useMemo } from 'react'
import Editor from '../components/editor/Editor'
import { getDraft, saveDraft } from '../storage/Draft'

const New: React.FC<{}> = () => {
  const initialValue = useMemo(getDraft, [])
  return <Editor
    initialValue={initialValue ?? ''}
    backupText={textArea => {
      if (!textArea.dataset.id) {
        saveDraft(textArea.value)
      }
    }}
  />
}

export default New
