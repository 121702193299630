import { getContent, getHome, getMe, getNextPath, getRefreshTokenList, getText, getTextHisotry, getTextList, getTextSnapshot } from '../api/ApiRequest'
import { Models } from '../api/Models'
import { NetworkStateReducer, updateTokenIfNeeded } from '../store/NetworkStore'
import { convertToProjectListItem, convertToSectionListItem, convertToText, convertToTextListItem, ProjectListItemData, SectionListItemData, TextData, TextListItemData } from './Models'

export async function fetchHome (network: NetworkStateReducer): Promise<{
  home: {
    recentTexts: TextListItemData[]
    allProjects: ProjectListItemData[]
  }
}> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await getHome(accessToken)
  return {
    home: {
      recentTexts: result.texts.map(convertToTextListItem),
      allProjects: result.contents.map(convertToProjectListItem)
    }
  }
}

export async function fetchMe (network: NetworkStateReducer): Promise<{
  me: Models.Me
}> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await getMe(accessToken)
  return result
}

export async function fetchTextList (network: NetworkStateReducer): Promise<{
  textList: {
    texts: TextListItemData[]
  }
}> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await getTextList(accessToken)
  return {
    textList: {
      texts: result.texts.map(convertToTextListItem)
    }
  }
}

export async function fetchProject (id: number, network: NetworkStateReducer): Promise<{
  project: {
    project: ProjectListItemData
    sections: SectionListItemData[]
  }
}> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await getContent(id, accessToken)
  return {
    project: {
      project: convertToProjectListItem(result.content),
      sections: result.contents.map(convertToSectionListItem)
    }
  }
}

export async function fetchText (id: number, network: NetworkStateReducer): Promise<{
  text: TextData
}> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await getText(id, accessToken)
  return {
    text: convertToText(result.text)
  }
}

export async function fetchTextHistory (id: number, network: NetworkStateReducer): Promise<{
  textHistory: Models.TextHistory[]
  nextPath?: string
}> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await getTextHisotry(id, accessToken)
  return result
}

export async function fetchTextSnapshot (id: number, revision: number, network: NetworkStateReducer): Promise<{
  textSnapshot: Models.TextHistoryDetail
}> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await getTextSnapshot(id, revision, accessToken)
  return result
}

export async function fetchRefreshTokenList (network: NetworkStateReducer): Promise<{
  refreshTokens: Models.RefreshToken[]
}> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await getRefreshTokenList(accessToken)
  return result
}

export async function fetchNextPath <T> (nextPath: string, network: NetworkStateReducer): Promise<T> {
  const accessToken = await updateTokenIfNeeded(network)
  const result = await getNextPath<T>(nextPath, accessToken)
  return result
}
