import React from 'react'
import LoadingIcon from '../loading/LoadingIcon'

const LoadingModal: React.FC<{}> = () => {
  return <div className={'fixed w-full h-full top-0 left-0 flex items-center justify-center'}>
    <LoadingIcon/>
  </div>
}

export default LoadingModal
