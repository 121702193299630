import React from 'react'
import styled from 'styled-components'

const PolicyHeader: React.FC<{ children: React.ReactNode }> = props => {
  return <h3 className={'mt-32px mb-16px text-16px md:text-24px'}>{props.children}</h3>
}

const StyledListItem = styled.li`
&:before {
  content: '・'
}
`

const PolicyItem: React.FC<{ children: React.ReactNode }> = props => {
  return <StyledListItem>{props.children}</StyledListItem>
}

const Privacy: React.FC<{}> = () => {
  return (
    <div className={'font-mincho p-24px md:p-96px leading-document'}>
      <h2 className={'mb-16px text-24px md:text-40px'}>プライバシーポリシー</h2>
      <p>TATEditor（以下、当サービスといいます。）は、個人情報の取扱い及び保護の重要性について認識し、以下のプライバシーポリシー（以下、本ポリシーといいます。）を設置しております。</p>
      <p>当サービスは法令を遵守すると共に、本ポリシーに従い、個人情報の適切な取扱い及び保護に努めます。</p>

      <PolicyHeader>1. 適用範囲</PolicyHeader>
      <p>本ポリシーは当サービス全体に適用されます。</p>
      <p>当サービスは以下によって構成されています。</p>
      <ul>
        <PolicyItem>Web版 TATEditor (<a className={'link'} href={'https://tateditor.app/'}>https://tateditor.app/</a>)</PolicyItem>
        <PolicyItem>iOS版 TATEditor (<a className={'link'} href={'https://apps.apple.com/app/id1477764116'}>https://apps.apple.com/app/id1477764116</a>)</PolicyItem>
        <PolicyItem>Android版 TATEditor (<a className={'link'} href={'https://play.google.com/store/apps/details?id=net.cc4966.tateditor'}>https://play.google.com/store/apps/details?id=net.cc4966.tateditor</a>)</PolicyItem>
        <PolicyItem>TATEditor for VS Code (<a className={'link'} href={'https://marketplace.visualstudio.com/items?itemName=tateditor.app'}>https://marketplace.visualstudio.com/items?itemName=tateditor.app</a>)</PolicyItem>
      </ul>

      <PolicyHeader>2. 履歴情報および特性情報の取得について</PolicyHeader>
      <p>当サービスは、利用状況の把握のためにアクセス情報及びクラッシュ情報を収集しています。</p>

      <PolicyHeader>3. 情報の収集方法について</PolicyHeader>
      <p>当サービスは、情報の収集にグーグル株式会社より提供されているGoogle Analyticsを利用しています。</p>
      <p>グーグル株式会社のプライバシーポリシーについては以下のページをご参照ください。</p>
      <ul>
        <PolicyItem><a href={'https://policies.google.com/privacy'}>https://policies.google.com/privacy</a></PolicyItem>
      </ul>

      <PolicyHeader>4. 個人情報の利用について</PolicyHeader>
      <p>当サービスは、以下に定める目的のために個人情報を利用します。</p>
      <ul>
        <PolicyItem>当サービスの提供、運営又は改善のため</PolicyItem>
        <PolicyItem>当サービスへのお問い合わせ対応のため</PolicyItem>
        <PolicyItem>当サービスに関連する情報をお知らせするため</PolicyItem>
      </ul>

      <PolicyHeader>5. 個人情報の利用及び提供の制限について</PolicyHeader>
      <p>当サービスは、次に掲げる場合を除き、収集した情報を4.に定めた目的以外に利用又は提供いたしません。</p>
      <ul>
        <PolicyItem>法令に基づく場合</PolicyItem>
        <PolicyItem>本人の同意がある場合又は本人に提供する場合</PolicyItem>
        <PolicyItem>統計的に処理され個人を識別できない情報である場合</PolicyItem>
      </ul>

      <PolicyHeader>6. 個人情報の開示、訂正及び利用停止について</PolicyHeader>
      <p>当サービスは、本人の個人情報についてお問い合わせがあった場合には、合理的な範囲で対応します。</p>

      <PolicyHeader>7. 個人情報の管理について</PolicyHeader>
      <p>当サービスは、収集した個人情報の漏洩の防止のために必要な措置を合理的な範囲で講じます。</p>

      <PolicyHeader>8. 本ポリシーの改善について</PolicyHeader>
      <p>当サービスは、個人情報の運用状況を社会的な要求に照らし合わせ、本ポリシーの継続的な改善に務めるものとし、予告なく本ポリシーを改訂することがあります。</p>

      <PolicyHeader>変更履歴</PolicyHeader>
      <p>2020/11/24 プライバシーポリシー設置</p>
      <p>2021/08/29 iOS版の記述を追記</p>
      <p>2021/10/31 Android版の記述を追記</p>
      <p>2022/05/05 Visual Studio Code拡張の記述を追記</p>
    </div>
  )
}

export default Privacy
