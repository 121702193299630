import styled from 'styled-components'

const SearchInput = styled.input`
outline: none;
background-color: transparent;
background-image: url('/images/search_black_24dp.svg');
@media (prefers-color-scheme: dark) {
  background-image: url('/images/search_white_24dp.svg');
}
background-repeat: no-repeat;
background-position-x: 16px;
background-position-y: 12px;
&:focus {
  background-image: none;
  @media (min-width: 768px) {
    background-image: url('/images/search_black_24dp.svg');
    @media (prefers-color-scheme: dark) {
      background-image: url('/images/search_white_24dp.svg');
    }
  }
}
&:not(:focus) {
  padding-left: 48px;
}
&:not(:focus) ~ section {
  transform: translateY(-400px);
  opacity: 0;
  user-select: none;
}
`

export default SearchInput
