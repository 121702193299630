import React, { useContext, useEffect, useState } from 'react'
import { characterCountOfText, titleOfText, displayNumberOfText, TextListItemData, updateTimeOfText } from '../../../repository/Models'
import { ReactComponent as MoreVertIcon } from 'material-design-icons/navigation/svg/production/ic_more_vert_24px.svg'
import Sortable from '../Sortable'
import { useAppDispatch } from '../../../app/hooks'
import { NetworkStoreContext } from '../../../store/NetworkStore'
import { showTextContextMenu } from '../../../app/server'
import { getTemporaryText } from '../../../storage/Draft'

export interface TextLineItemProps {
  text: TextListItemData
}

const TextLineItem: React.FC<TextLineItemProps> = props => {
  const dispatch = useAppDispatch()
  const network = useContext(NetworkStoreContext)
  const path = `/texts/${props.text.id}/edit`
  const [hasDraft, setHasDraft] = useState(false)
  useEffect(() => {
    getTemporaryText(props.text.id)
      .then(temp => {
        if (temp) {
          setHasDraft(temp !== null)
        }
      })
  }, [props.text.id])
  return <Sortable type={'list'} item={props.text} path={path}>
    <div className={'flex items-center h-full'}>
      <div className={'ml-16px overflow-x-auto overflow-y-hidden whitespace-nowrap flex-grow'}>
        <h4>{titleOfText(props.text)}</h4>
      </div>
      <div className={'ml-16px text-right overflow-x-auto overflow-y-hidden whitespace-nowrap shrink-0'}>{characterCountOfText(props.text)}</div>
      <div className={'w-48px text-right overflow-x-auto overflow-y-hidden whitespace-nowrap shrink-0'}>{displayNumberOfText(props.text)}</div>
      <div className={'ml-16px w-216px text-right overflow-x-auto overflow-y-hidden whitespace-nowrap hidden sm:block shrink'}>{updateTimeOfText(props.text)}{hasDraft ? '*' : ''}</div>
      <MoreVertIcon className={'p-8px w-40px h-40px m-4px rounded-full shrink-0 hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={e => {
        showTextContextMenu(e, dispatch, network, props.text)
        e.preventDefault()
      }} />
    </div>
  </Sortable>
}

export default TextLineItem
