import React, { useRef } from 'react'

const SelectModal: React.FC<{
  message: string
  label: string
  defaultValue?: string | undefined
  optionList: string[]
  positive: {
    label: string
    onSubmit: (value: string) => void
  }
  negative: {
    label: string
    onClick: () => void
  }
}> = props => {
  const select = useRef<HTMLSelectElement>(null)
  return <div className={'p-16px flex flex-col'}>
    <p className={'mb-16px'}>{props.message}</p>
    <div className={'flex items-center justify-between mb-16px'}>
      <label>{props.label}</label>
      <select className={'p-4px border rounded-4px'} ref={select} defaultValue={props.defaultValue}>
        {props.optionList.map((item, i) => {
          return <option key={i} value={item}>{item}</option>
        })}
      </select>
    </div>
    <div className={'flex justify-end'}>
      <button className={'btn-normal mr-8px'} onClick={props.negative.onClick}>{props.negative.label}</button>
      <button className={'btn-primary'} onClick={() => {
        if (select.current) {
          props.positive.onSubmit(select.current.value)
        }
      }}>{props.positive.label}</button>
    </div>
  </div>
}

export default SelectModal
