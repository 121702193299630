import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import ActionMenuHeader from '../components/list/ActionMenuHeader'
import CardListContainer from '../components/list/CardListContainer'
import LineListContainer from '../components/list/LineListContainer'
import ListHeader from '../components/list/ListHeader'
import PartHeader from '../components/list/PartHeader'
import PageLoad from '../components/loading/PageLoad'
import { fetchProject } from '../repository/Fetch'
import { ProjectListItemData, SectionListItemData, TextSectionListItemData } from '../repository/Models'
import { getSetting, saveSetting } from '../storage/Settings'
import { selectProject, setProject } from '../store/serverSlice'

const Project: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()
  const projectId = parseInt(id)
  const dispatch = useAppDispatch()
  const project = useAppSelector(state => selectProject(state, projectId))
  const [viewCard, setViewCard] = useState(getSetting('project-view-section-list') === 'card')
  if (!project) {
    return <PageLoad<{
      project: {
        project: ProjectListItemData
        sections: SectionListItemData[]
      }
    }>
      fetch={accessToken => fetchProject(projectId, accessToken)}
      dispatch={response => dispatch(setProject(response.project))}
    />
  }
  return <>
    <div className={'fixed w-full h-48px border-b border-lightBorder dark:border-darkBorder bg-lightSurface dark:bg-darkSurface'}>
      <ActionMenuHeader mode={{ project: project.project }} />
    </div>
    <div className={'mt-48px p-8px md:px-40px md:py-24px'}>
      <PartHeader type={'project'} />
      <CardListContainer oneline={true} project={project.project} sections={project.sections.flatMap<TextSectionListItemData>(section => section.text ? [section] : []).sort((a, b) => -(a.text.savedTimestamp - b.text.savedTimestamp))} />
      <ListHeader
        title='すべてのエピソード'
        changeToListView={() => {
          saveSetting('project-view-section-list', 'list')
          setViewCard(false)
        }}
        changeToCardView={() => {
          saveSetting('project-view-section-list', 'card')
          setViewCard(true)
        }} />
      {viewCard
        ? <CardListContainer project={project.project} sections={project.sections} />
        : <LineListContainer project={project.project} sections={project.sections} />}
    </div>
  </>
}

export default Project
