import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import { putIdbHelp } from '../database/helpHistory/HelpHistoryDao'
import { IdbHelp } from '../database/helpHistory/HelpHistoryDatabase'

export interface Article {
  id: string
  title: string
  content: string
  categories: string[]
  platforms: string[]
  keywords: string[]
  faq: 'Yes' | 'No'
  request: 'Yes' | 'No'
  published: number
  revised: number
}

// Define a type for the slice state
interface HelpState {
  help?: {
    articles: Article[]
  }
  history: IdbHelp[]
}

// Define the initial state using that type
const initialState: HelpState = {
  history: []
}

export const helpSlice = createSlice({
  name: 'helpSlice',
  initialState,
  reducers: {
    setHelp: (state, action: PayloadAction<{
      articles: Article[]
      history: IdbHelp[]
    }>) => {
      state.help = {
        articles: action.payload.articles
      }
      state.history = action.payload.history
    },
    addHelpHistory: (state, action: PayloadAction<Article>) => {
      const oldIndex = state.history.findIndex(item => item.id === action.payload.id)
      if (oldIndex >= 0) {
        const oldItem = state.history.splice(oldIndex, 1)[0]
        const newItem: IdbHelp = {
          id: action.payload.id,
          viewCount: oldItem.viewCount + 1,
          createdTimestamp: oldItem.createdTimestamp,
          updatedTimestamp: new Date().getTime()
        }
        state.history.splice(0, 0, newItem)
        putIdbHelp(newItem)
      } else {
        const newItem: IdbHelp = {
          id: action.payload.id,
          viewCount: 1,
          createdTimestamp: new Date().getTime(),
          updatedTimestamp: new Date().getTime()
        }
        state.history.splice(0, 0, newItem)
        putIdbHelp(newItem)
      }
    }
  }
})

export const { setHelp, addHelpHistory } = helpSlice.actions

export const selectHelp = (state: RootState) => state.helpSlice.help
export const selectHelpHistory = (state: RootState) => state.helpSlice.history

export default helpSlice.reducer
