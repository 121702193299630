import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'

// Define a type for the slice state
interface SideMenuState {
  isOpened: boolean
}

// Define the initial state using that type
const initialState: SideMenuState = {
  isOpened: window.matchMedia('(min-width: 768px)').matches
}

export const sideMenuSlice = createSlice({
  name: 'sideMenuSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    closeSideMenu: (state) => {
      state.isOpened = false
    },
    openSideMenu: (state) => {
      state.isOpened = true
    },
    toggleSideMenu: (state) => {
      state.isOpened = !state.isOpened
    }
  }
})

export const { closeSideMenu, openSideMenu, toggleSideMenu } = sideMenuSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectIsOpened = (state: RootState) => state.sideMenuSlice.isOpened

export default sideMenuSlice.reducer
