import React from 'react'
import { Link, Route, Switch, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { toggleSideMenu } from '../store/sideMenuSlice'
import { ReactComponent as Logo } from '../img/tateditor-icon.svg'
import { ReactComponent as MenuIcon } from 'material-design-icons/navigation/svg/production/ic_menu_24px.svg'
import { ReactComponent as MoreVertIcon } from 'material-design-icons/navigation/svg/production/ic_more_vert_24px.svg'
import { ReactComponent as LibraryBooksIcon } from 'material-design-icons/av/svg/production/ic_library_books_24px.svg'
import { ReactComponent as FolderIcon } from 'material-design-icons/file/svg/production/ic_folder_24px.svg'
import { ReactComponent as HomeIcon } from 'material-design-icons/action/svg/production/ic_home_24px.svg'
import { ReactComponent as GavelIcon } from 'material-design-icons/action/svg/production/ic_gavel_24px.svg'
import { ReactComponent as SecurityIcon } from 'material-design-icons/hardware/svg/production/ic_security_24px.svg'
import { ReactComponent as VerifiedUserIcon } from 'material-design-icons/action/svg/production/ic_verified_user_24px.svg'
import { ReactComponent as AccountCircleIcon } from 'material-design-icons/action/svg/production/ic_account_circle_48px.svg'
import { ReactComponent as FeedbackIcon } from 'material-design-icons/action/svg/production/ic_feedback_24px.svg'
import { ReactComponent as QuestionAnswerIcon } from 'material-design-icons/action/svg/production/ic_question_answer_24px.svg'
import { ReactComponent as HelpIcon } from 'material-design-icons/action/svg/production/ic_help_24px.svg'
import { ReactComponent as AppsIcon } from 'material-design-icons/navigation/svg/production/ic_apps_24px.svg'
import { ReactComponent as HistoryIcon } from 'material-design-icons/action/svg/production/ic_history_24px.svg'
import { ReactComponent as ShareIcon } from 'material-design-icons/social/svg/production/ic_share_24px.svg'
import { ReactComponent as FileDownloadIcon } from 'material-design-icons/file/svg/production/ic_file_download_24px.svg'
import { logout, selectHasLoggedIn } from '../store/userSlice'
import { showModal } from '../store/modalSlice'
import FeedbackModal from './modals/FeedbackModal'
import SearchBar from './editor/SearchBar'
import { download, downloadText, share } from '../app/editor'
import { selectText } from '../store/serverSlice'
import { TextData } from '../repository/Models'
import { showContextMenu } from '../store/contextMenuSlice'
import { AppDispatch } from '../app/store'
import TextHistoryModal from './modals/TextHistoryModal'

const AppLogo: React.FC<{
  isEditor?: true
}> = props => {
  return <div className={`${props.isEditor ? 'hidden md:flex' : 'flex'} justify-start w-184px shrink-0`}>
    <Link className={'flex items-center'} to="/"><Logo className={'hidden md:block rounded-4px mr-8px'} />TATEditor</Link>
  </div>
}

const AppHeader: React.FC<{
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  children: React.ReactNode
} | {
  isEditor: boolean
}> = props => {
  if ('icon' in props) {
    return <>
      <AppLogo />
      <h2 className={'hidden md:flex items-center text-24px font-bold'}>
        <props.icon className={'ml-24px mr-8px'} />{props.children}
      </h2>
    </>
  }
  if (props.isEditor) {
    return <>
      <AppLogo isEditor={true} />
      <SearchBar />
    </>
  }
  return <AppLogo />
}

const PageTitle: React.FC<{}> = () => {
  return <Switch>
    <Route path="/new" exact><AppHeader isEditor={true} /></Route>
    <Route path="/texts/:id/edit"><AppHeader isEditor={true} /></Route>
    <Route path="/projects/:id"><AppHeader icon={FolderIcon}>プロジェクト</AppHeader></Route>
    <Route path="/texts"><AppHeader icon={LibraryBooksIcon}>テキスト一覧</AppHeader></Route>
    <Route path="/home"><AppHeader icon={HomeIcon}>ホーム</AppHeader></Route>
    <Route path="/contact"><AppHeader icon={QuestionAnswerIcon}>お問い合わせ</AppHeader></Route>
    <Route path="/privacy"><AppHeader icon={SecurityIcon}>プライバシーポリシー</AppHeader></Route>
    <Route path="/tos"><AppHeader icon={GavelIcon}>利用規約</AppHeader></Route>
    <Route path="/me"><AppHeader icon={VerifiedUserIcon}>アカウント情報</AppHeader></Route>
    <Route path="/help"><AppHeader icon={HelpIcon}>ヘルプ</AppHeader></Route>
    <Route path="/auth/authorization"><AppHeader icon={AppsIcon}>アクセスの許可</AppHeader></Route>
    <Route path="/auth/credentials"><AppHeader icon={AppsIcon}>アクセス権限の管理</AppHeader></Route>
    <Route path="/"><AppHeader isEditor={false} /></Route>
  </Switch>
}

const DownloadText: React.FC<{
  text?: TextData
}> = props => {
  const dispatch = useAppDispatch()
  if ('share' in navigator) {
    return <ShareIcon
      title='シェア'
      className={'hidden md:block mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
      onClick={e => downloadText(dispatch, e, props.text)}
    />
  } else {
    return <FileDownloadIcon
      title='ダウンロード'
      className={'hidden md:block mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
      onClick={() => download(props.text)}
    />
  }
}

const DownloadContent: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()
  const textId = parseInt(id)
  const text = useAppSelector(state => selectText(state, textId))
  return <DownloadText text={text} />
}

const Feedback: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  return <FeedbackIcon
    title='フィードバック'
    className={'hidden md:block mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={() => dispatch(showModal({ component: FeedbackModal }))}
  />
}

const Account: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  // AccountCircleIconのp-4pxはSafariのバグ回避（padding:0だとsvgの最大描画領域にのみ背景色が反映される）
  return <AccountCircleIcon
    title='アカウント'
    className={'hidden md:block mx-8px p-4px w-56px h-56px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={e => dispatch(showContextMenu({
      position: {
        clientX: e.clientX,
        clientY: e.clientY
      },
      items: [
        {
          label: 'アカウント情報',
          path: '/me'
        },
        {
          label: 'ログアウト',
          onClick () {
            dispatch(logout())
          }
        }
      ]
    }))}
  />
}

function openTextHistory (dispatch: AppDispatch, text: TextData) {
  dispatch(showModal({
    component () {
      return <TextHistoryModal
        text={text}
      />
    }
  }))
}

const TextHistory: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()
  const textId = parseInt(id)
  const text = useAppSelector(state => selectText(state, textId))
  const dispatch = useAppDispatch()
  return <HistoryIcon
    title='保存履歴'
    className={'hidden md:block mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={e => {
      if (!text) {
        return
      }
      dispatch(showContextMenu({
        position: e,
        items: [
          {
            label: '保存履歴を表示',
            onClick () {
              openTextHistory(dispatch, text)
            }
          }
        ]
      }))
    }}
  />
}

function createTextMenu (dispatch: AppDispatch, text: TextData | undefined): ({
  label: string;
  onClick: React.MouseEventHandler<HTMLLIElement>;
} | {
  label: string;
  path: string;
})[] {
  const onFeedback = {
    label: 'フィードバック',
    onClick () {
      dispatch(showModal({ component: FeedbackModal }))
    }
  }
  const onShare = {
    label: 'シェア',
    onClick () {
      share(text)
    }
  }
  const onDownload = {
    label: 'ダウンロード',
    onClick () {
      download(text)
    }
  }
  const onOpenHistory = {
    label: '保存履歴を表示',
    onClick () {
      if (text) {
        openTextHistory(dispatch, text)
      }
    }
  }
  const menuItems = [onFeedback]
  if ('share' in navigator) {
    menuItems.push(onShare)
  }
  menuItems.push(onDownload)
  if (text) {
    menuItems.push(onOpenHistory)
  }
  return menuItems
}

const NewTextEditorMenu: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  return <MoreVertIcon
    className={'md:hidden mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={e => dispatch(showContextMenu({
      position: {
        clientX: e.clientX,
        clientY: e.clientY
      },
      items: createTextMenu(dispatch, undefined)
    }))}
  />
}

const TextEditorMenu: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()
  const textId = parseInt(id)
  const text = useAppSelector(state => selectText(state, textId))
  const dispatch = useAppDispatch()
  return <MoreVertIcon
    className={'md:hidden mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={e => {
      if (!text) {
        return
      }
      dispatch(showContextMenu({
        position: {
          clientX: e.clientX,
          clientY: e.clientY
        },
        items: createTextMenu(dispatch, text)
      }))
    }}
  />
}

const ActionItems: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  return <div className={'flex items-center text-24px shrink-0'}>
    <Switch>
      <Route path="/new" exact>
        <DownloadText />
        <Account />
        <NewTextEditorMenu />
      </Route>
      <Route path="/texts/:id/edit">
        <TextHistory />
        <DownloadContent />
        <Account />
        <TextEditorMenu />
      </Route>
      <Route path="/">
        <Feedback />
        <Account />
        <MoreVertIcon
          className={'md:hidden mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
          onClick={e => dispatch(showContextMenu({
            position: {
              clientX: e.clientX,
              clientY: e.clientY
            },
            items: [
              {
                label: 'フィードバック',
                onClick () {
                  dispatch(showModal({ component: FeedbackModal }))
                }
              },
              {
                label: 'アカウント情報',
                path: '/me'
              },
              {
                label: 'ログアウト',
                onClick () {
                  dispatch(logout())
                }
              }
            ]
          }))}
        />
      </Route>
    </Switch>
  </div>
}

const SignInButton: React.FC<{}> = () => {
  return <div>
    <Link to="/signin" className={'mr-12px'}>新規登録・ログイン</Link>
  </div>
}

const Header: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const hasLoggedIn = useAppSelector(selectHasLoggedIn)
  if (hasLoggedIn) {
    return <div className={'flex mx-12px h-full items-center justify-between'}>
      <div className={'flex items-center flex-grow'}>
        <MenuIcon className={'p-12px mr-12px w-48px h-48px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover shrink-0'} onClick={() => dispatch(toggleSideMenu())} />
        <PageTitle />
      </div>
      <ActionItems />
    </div>
  } else {
    return <div className={'flex mx-12px h-full items-center justify-between'}>
      <div className={'flex items-center flex-grow'}>
        <PageTitle />
      </div>
      <SignInButton />
    </div>
  }
}

export default Header
