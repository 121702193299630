import React, { useContext } from 'react'
import { ReactComponent as AddIcon } from 'material-design-icons/content/svg/production/ic_add_24px.svg'
import { useHistory } from 'react-router-dom'
import { ProjectListItemData } from '../../../repository/Models'
import Sortable from '../Sortable'
import { showNewProjectModal, showNewTextSectionModal } from '../../../app/server'
import { useAppDispatch } from '../../../app/hooks'
import { NetworkStoreContext } from '../../../store/NetworkStore'

interface Props {
  type: 'project' | 'text'
  parent: ProjectListItemData | null
}

const EmptyCard: React.FC<Props> = props => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const network = useContext(NetworkStoreContext)
  return <Sortable type={'card'} onClick={() => {
    if (props.type === 'project') {
      showNewProjectModal(dispatch, network, result => history.push(`/projects/${result.id}`))
    } else if (props.parent !== null) {
      const project = props.parent
      showNewTextSectionModal(dispatch, network, project, result => history.push(`/texts/${result.id}/edit`))
    } else {
      history.push('/new')
    }
  }}>
    <div className={'w-full h-full flex flex-col justify-center items-center'}>
      <AddIcon className={'w-48px h-48px'}/>
    </div>
  </Sortable>
}

export default EmptyCard
