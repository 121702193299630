import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'

export interface Toast {
  uuid: string
  type: 'error' | 'success' | 'info' | 'warning'
  message: string
  occuredTimestamp: number
  clearedTimestamp?: number
}

interface ToastState {
  toasts: Toast[]
}

// Define the initial state using that type
const initialState: ToastState = {
  toasts: []
}

export const ToastSlice = createSlice({
  name: 'toastSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<{
      type: 'error' | 'success' | 'info' | 'warning'
      message: string
    }>) => {
      const date = new Date()
      state.toasts = state.toasts.concat({
        ...action.payload,
        uuid: action.payload.message + date.getTime(),
        occuredTimestamp: date.getTime()
      })
    },
    dismissToast: (state, action: PayloadAction<string>) => {
      const date = new Date()
      state.toasts = state.toasts.map(toast => {
        return {
          ...toast,
          clearedTimestamp: toast.clearedTimestamp ?? (toast.uuid === action.payload ? date.getTime() : undefined)
        }
      })
    },
    clearToast: (state) => {
      if (state.toasts.length === 0) {
        return
      }
      const date = new Date()
      state.toasts = state.toasts.filter(toast => {
        return !toast.clearedTimestamp || toast.clearedTimestamp + 1000 > date.getTime()
      })
    }
  }
})

export const { showToast, dismissToast, clearToast } = ToastSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectToasts = (state: RootState) => state.toastSlice.toasts

export default ToastSlice.reducer
