import React from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectHasLoggedIn } from '../store/userSlice'
import { AppDispatch } from '../app/store'
import { showToast } from '../store/toastSlice'
import { showModal } from '../store/modalSlice'
import FeedbackModal from '../components/modals/FeedbackModal'
import { Link } from 'react-router-dom'

function openMailer (subject: string, body: string) {
  const address = 'tateditor@cc4966.net'
  location.href = encodeURI('mailto:' + address + '?subject=' + subject + '&body=' + body)
}

function support (dispatch: AppDispatch, hasLoggedIn: boolean) {
  if (!hasLoggedIn) {
    dispatch(showToast({
      type: 'error',
      message: 'ログインする必要があります'
    }))
    return
  }
  const subject = '[サポート] 件名にも簡潔な内容をお願いします'
  const body = `\n\n\n\nUA: ${navigator.userAgent}`
  openMailer(subject, body)
}

function request () {
  const subject = '[依頼] 件名にも簡潔な依頼内容をお願いします'
  const body = ''
  openMailer(subject, body)
}

function other () {
  const subject = '[その他] 件名にも簡潔な内容をお願いします'
  const body = ''
  openMailer(subject, body)
}

function feedback (dispatch: AppDispatch) {
  dispatch(showModal({ component: FeedbackModal }))
}

const Contact: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const hasLoggedIn = useAppSelector(selectHasLoggedIn)
  return (
    <div className={'min-h-heightMain flex flex-col items-center justify-around w-full bg-brand bg-opacity-10 bg-no-repeat bg-center bg-cover md:bg-contain bg-logo'}>
      <div className={'p-24px grid w-widthPanel max-w-widthPanel m-24px rounded-16px bg-lightSurface dark:bg-darkSurface shadow'}>
        <h2 className={'text-24px mb-40px'}>お問い合わせ</h2>
        <h3 className={'text-12px mt-8px'}>メール</h3>
        <button className={'p-8px mb-8px text-center rounded-8px shadow bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={() => support(dispatch, hasLoggedIn)}>ユーザーの方（サポート）</button>
        <button className={'p-8px mb-8px text-center rounded-8px shadow bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={request}>企業・メディアの方（依頼等）</button>
        <button className={'p-8px mb-8px text-center rounded-8px shadow bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={other}>その他のお問い合わせ（その他）</button>
        <h3 className={'text-12px mt-8px'}>Twitter</h3>
        <a className={'p-8px mb-8px text-center rounded-8px shadow bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} href={'https://twitter.com/intent/tweet?screen_name=496_'}>Twitterで聞く</a>
        <h3 className={'text-12px mt-8px'}>ヘルプ</h3>
        <Link className={'p-8px mb-8px text-center rounded-8px shadow bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} to={'/help'}>ヘルプを開く</Link>
        <h3 className={'text-12px mt-8px'}>返信不要の場合</h3>
        <button className={'p-8px mb-8px text-center rounded-8px shadow bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={() => feedback(dispatch)}>感想・要望（フィードバック）</button>
        <p className={'text-12px mt-40px'}>メールの返信には相当のお時間をいただくことがございます。</p>
      </div>
    </div>
  )
}

export default Contact
