import React, { createContext, useReducer } from 'react'
import { postToken } from '../api/AuthRequest'
import { getAuth } from 'firebase/auth'

export interface NetworkState {
  accessToken: string | null
  accessTokenExpireAt: number
  disabledUser: boolean
  maintenanceMode: boolean
}

export interface SetAccessToken {
  accessToken: string
}

export interface SetDisabledUser {
  disabledUser: boolean
}

export interface SetMaintenanceMode {
  maintenanceMode: boolean
}

export type NetworkAction = SetAccessToken | SetDisabledUser | SetMaintenanceMode

const initialState: NetworkState = {
  accessToken: null,
  accessTokenExpireAt: 0,
  disabledUser: false,
  maintenanceMode: false
}

export interface NetworkStateReducer {
  state: NetworkState
  dispatch: React.Dispatch<NetworkAction>
}

const NetworkStoreContext = createContext<NetworkStateReducer>({
  state: initialState,
  dispatch: () => {}
})

const NetworkStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer((state: NetworkState, action: NetworkAction): NetworkState => {
    if ('accessToken' in action) {
      return {
        ...state,
        accessToken: action.accessToken,
        accessTokenExpireAt: new Date().getTime() + (60 * 60 * 24 - 60) * 1000
      }
    }
    if ('disabledUser' in action) {
      return {
        ...state,
        disabledUser: action.disabledUser
      }
    }
    if ('maintenanceMode' in action) {
      return {
        ...state,
        maintenanceMode: action.maintenanceMode
      }
    }
    return action
  }, initialState)
  return (
    <NetworkStoreContext.Provider value={{ state, dispatch }}>{children}</NetworkStoreContext.Provider>
  )
}

export async function updateTokenIfNeeded (network: NetworkStateReducer): Promise<string> {
  if (network.state.accessToken && network.state.accessTokenExpireAt > new Date().getTime()) {
    return network.state.accessToken
  }
  const user = getAuth().currentUser
  if (user === null) {
    throw new Error('No user')
  }
  // WIP: device_id
  return new Promise<string>((resolve, reject) => {
    postToken(user)
      .then(result => {
        network.dispatch(result)
        resolve(result.accessToken)
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          network.dispatch({ disabledUser: true })
        }
        if (error?.response?.status === 503) {
          network.dispatch({ maintenanceMode: true })
        }
        reject(error)
      })
  })
}

export { NetworkStoreContext, NetworkStateProvider }
