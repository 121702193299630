import helpHistoryDatabase, { IdbHelp } from './HelpHistoryDatabase'

export async function getAllIdbHelp (): Promise<IdbHelp[]> {
  const db = await helpHistoryDatabase
  const history = await db.getAllFromIndex('helpHistory', 'index_updatedTimestamp')
  return history.reverse()
}

export async function deleteIdbHelp (id: string): Promise<void> {
  const db = await helpHistoryDatabase
  return db.delete('helpHistory', id)
}

export async function putIdbHelp (value: IdbHelp): Promise<string> {
  const db = await helpHistoryDatabase
  return db.put('helpHistory', value)
}
