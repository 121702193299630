import React from 'react'
import LoadingIcon from './LoadingIcon'

const LoadingCover: React.FC = () => {
  return <div className={'fixed top-0 left-0 w-full h-full flex justify-center items-center pointer-events-none'}>
    <LoadingIcon/>
  </div>
}

export default LoadingCover
