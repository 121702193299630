type SettingsKey =
  'home-view-project-list' |
  'project-view-section-list' |
  'text-list-view' |
  'editor-font-typeface' |
  'editor-font-size' |
  'editor-white-space'

function save (key: SettingsKey, value: string) {
  if (!localStorage) {
    return
  }
  localStorage.setItem(key, value)
}

function get (key: SettingsKey): string | null {
  if (!localStorage) {
    return null
  }
  return localStorage.getItem(key)
}

export function saveSetting (key: SettingsKey, value: string) {
  try {
    save(key, value)
  } catch (error) {
    console.error(error)
  }
}

export function getSetting (key: SettingsKey): (string | null) {
  try {
    return get(key)
  } catch (error) {
    console.error(error)
    return null
  }
}
