import React, { useContext } from 'react'
import { characterCountOfProject, titleOfProject, textCountOfProject, ProjectListItemData, updateTimeOfProject } from '../../../repository/Models'
import { ReactComponent as MoreVertIcon } from 'material-design-icons/navigation/svg/production/ic_more_vert_24px.svg'
import Sortable from '../Sortable'
import { useAppDispatch } from '../../../app/hooks'
import { NetworkStoreContext } from '../../../store/NetworkStore'
import { showProjectContextMenu } from '../../../app/server'

export interface ProjectLineItemProps {
  project: ProjectListItemData
}

const ProjectLineItem: React.FC<ProjectLineItemProps> = props => {
  const dispatch = useAppDispatch()
  const network = useContext(NetworkStoreContext)
  const path = props.project.text ? `/texts/${props.project.text.id}/edit` : `/projects/${props.project.id}`
  return <Sortable type={'list'} item={props.project} path={path}>
    <div className={'flex items-center h-full'}>
      <div className={'ml-16px overflow-x-auto overflow-y-hidden whitespace-nowrap flex-grow'}>
        <h4>{titleOfProject(props.project)}</h4>
      </div>
      <div className={'ml-16px text-right overflow-x-auto overflow-y-hidden whitespace-nowrap shrink-0'}>{characterCountOfProject(props.project)}</div>
      <div className={'w-48px text-right overflow-x-auto overflow-y-hidden whitespace-nowrap shrink-0'}>{textCountOfProject(props.project)}</div>
      <div className={'ml-16px w-216px text-right overflow-x-auto overflow-y-hidden whitespace-nowrap hidden sm:block shrink'}>{updateTimeOfProject(props.project)}</div>
      <MoreVertIcon className={'p-8px w-40px h-40px m-4px rounded-full shrink-0 hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={e => {
        showProjectContextMenu(e, dispatch, network, props.project)
        e.preventDefault()
      }} />
    </div>
  </Sortable>
}

export default ProjectLineItem
