import React, { useEffect } from 'react'
import { getApps, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { getAnalytics, setUserId } from 'firebase/analytics'
import firebaseConfig from '../config/firebase'

import Container from '../components/Container'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectIsLoadingUser, applyUserState } from '../store/userSlice'
import { NetworkStateProvider } from '../store/NetworkStore'
import LoadingUser from '../components/loading/LoadingUser'
import { BrowserRouter } from 'react-router-dom'

function useUser (): void {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (process.env.REACT_APP_TATEDITOR_AUTH) {
      try {
        if (!getApps().length) { // hot loader
          initializeApp({
            apiKey: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
            appId: 'x-local-emu',
            projectId: 'x-local-emu',
            authDomain: 'x-local-emu.firebaseapp.com'
          })
        }
        connectAuthEmulator(getAuth(), process.env.REACT_APP_TATEDITOR_AUTH, {
          disableWarnings: true
        })
        getAuth().onAuthStateChanged(user => {
          if (!process.env.REACT_APP_TATEDITOR_AUTH) {
            setUserId(getAnalytics(), user?.uid ?? '')
          }
          dispatch(applyUserState(user))
        }, () => {
          // ignore
        })
      } catch {
        // ignore
      }
    } else {
      if (!getApps().length) { // hot loader
        initializeApp(firebaseConfig)
      }
      getAnalytics()
      getAuth().onAuthStateChanged(user => {
        if (!process.env.REACT_APP_TATEDITOR_AUTH) {
          setUserId(getAnalytics(), user?.uid ?? '')
        }
        dispatch(applyUserState(user))
      })
    }
  })
}

const App: React.FC = () => {
  useUser()
  const isLoadingUser = useAppSelector(selectIsLoadingUser)
  if (isLoadingUser) {
    return <LoadingUser/>
  }
  return <NetworkStateProvider>
    <BrowserRouter>
      <Container/>
    </BrowserRouter>
  </NetworkStateProvider>
}

export default App
