import { configureStore } from '@reduxjs/toolkit'
import sideMenuSlice from '../store/sideMenuSlice'
import userSlice from '../store/userSlice'
import contextMenuSlice from '../store/contextMenuSlice'
import modalSlice from '../store/modalSlice'
import toastSlice from '../store/toastSlice'
import editorSlice from '../store/editorSlice'
import serverSlice from '../store/serverSlice'
import helpSlice from '../store/helpSlice'

const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: !process.env.REACT_APP_TATEDITOR_AUTH }),
  reducer: {
    sideMenuSlice,
    userSlice,
    contextMenuSlice,
    modalSlice,
    toastSlice,
    editorSlice,
    serverSlice,
    helpSlice
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
